<template>
    <b-container >
        <b-card no-body class="container bg-light" >

            <b-row class="bg-light" >
                <b-col class="ml-1 text-left" cols="1">
                    <b-icon icon="chevron-left" style="width: 25px; height: 25px;margin-left: -15px"  @click="onGoback"></b-icon>
                </b-col>
                <b-col class="text-center" cols="10">
                    <h5><b>时间充值</b>  </h5>
                </b-col>
            </b-row>

            <b-row >
                <b-col class="text-center" cols="12" style="display: block;margin-top: 10px;margin-bottom: 10px" >
                    <b-card class="text-left " style="background-color: burlywood">
                        <h6   style="margin-bottom: -0.1rem ">账户余额</h6>
                        <span style="font-size: 0.8rem;display: inline-block;" > {{spacetimeStr}}</span>
                        <b-row >

                                <b-col cols="1"  style="min-width: 4rem;max-width:4rem;">
                                    <b-img :src="userInfo.header"  rounded="circle" style="width: 50px;height: 50px ;" alt="头像"></b-img>
                                </b-col>

                                <b-col    style="margin-left: -0.5rem;font-size: small;text-align: left;margin-top: 0.3rem" >
                                    {{userInfo.nickName}}({{userInfo.phone}})
                                    <br>
                                    <span v-if="transaction.cord.cardOnceType==1">
                                        到期时间：{{transaction.cord.endTime}}
                                    </span>
                                    <span v-else-if="transaction.cord.cardContinuedType==1">
                                        连续包月
                                    </span>
                                    <span v-else>
                                        未开通月卡
                                    </span>
                                </b-col>


                        </b-row>
                    </b-card>
                </b-col>
            </b-row>


            <b-row style="display: block;margin-top: 5px;margin-bottom: 10px;font-size: 0.8rem">
                <b-col class="text-center " cols="12" >
                    <b-card  no-body  >
                        <b-tabs pills card fill
                                nav-wrapper-class="text-muted bg-white pt-0 pb-0 "
                                nav-item-class="text-muted bg-white "
                                active-nav-item-class="text-dark bg-white "
                               >
                            <b-tab title="月卡"   :title-item-class="{'tab-title-class':type!=1}"  active flush  @click="checkItemType(1,$event)">
                                <b-card-group deck  class="row m-0 text-center" >

                                    <b-card
                                        v-for="item in transaction.cord"
                                        :key="item.id"
                                        :class="{'col-5':true ,'m-2':true, 'p-0':true,'border-warning':isActive==item.id}"
                                        @click="checkItem(item.id,$event)"

                                        >
                                        <h9>{{item.name}} </h9>
                                        <span class="text-danger" > ￥ {{item.price}}</span>
                                        <br>
                                        <span class="text-muted" style="text-decoration:line-through;"> 原价￥ {{item.originalPrice}}</span>
                                        <template v-slot:footer>
                                            <small class="text-muted">{{item.discount}}</small>
                                        </template>
                                    </b-card>

                                </b-card-group>
                            </b-tab>
                            <b-tab title="时间"    title-item-class="tab-title-class"  @click="checkItemType(2,$event)" >
                                <b-card-group deck  class="row m-0">

                                    <b-card
                                            v-for="item in transaction.time"
                                            :key="item.id"
                                            :class="{'col-5':true , 'ml-2':true, 'p-0':true, 'border-warning':isActive==item.id}"
                                            @click="checkItem(item.id,$event)"
                                    >
                                        <span  v-if= "item.presenter>0" style="display: block;background-color: red;position:absolute;width: 40px;height: 20px;top:0px;right: -0px">
                                            +{{ item.presenter }}
                                        </span>

                                        <h9>{{item.name}} </h9>
                                        <br>

                                        <span class="text-danger"> ￥ {{item.price}}</span>
                                        <br>
                                        <span class="text-muted" style="text-decoration:line-through;"> 原价￥ {{item.originalPrice}}</span>
                                        <template v-slot:footer>
                                            <small class="text-muted">{{item.discount}}</small>
                                        </template>
                                    </b-card>

                                </b-card-group>
                            </b-tab>


                            <b-tab title="测试"    title-item-class="tab-title-class" @click="checkItemType(3,$event)" >
                                <b-card-group deck  class="row m-0">

                                    <b-card
                                            v-for="item in transaction.test"
                                            :key="item.id"
                                            :class="{'col-5':true , 'ml-2':true, 'p-0':true, 'border-warning':isActive==item.id}"
                                            @click="checkItem(item.id,$event)"
                                    >
                                            <span  v-if= "item.presenter>0" style="display: block;background-color: red;position:absolute;width: 40px;height: 20px;top:0px;right: -0px">
                                                +{{ item.presenter }}
                                            </span>

                                        <h9>{{item.name}} </h9>
                                        <br>

                                        <span class="text-danger"> ￥ {{item.price}}</span>
                                        <br>
                                        <span class="text-muted" style="text-decoration:line-through;"> 原价￥ {{item.originalPrice}}</span>
                                        <template v-slot:footer>
                                            <small class="text-muted">{{item.discount}}</small>
                                        </template>
                                    </b-card>

                                </b-card-group>
                            </b-tab>
                        </b-tabs>

                    </b-card>
                </b-col>

            </b-row>

            <b-row >
                <b-col class="text-left " cols="12"  style="display: block;margin-bottom: 5px;font-size: 0.8rem">
                    <div class="bg-white p-1" >
                        <div class='p-l'  style="display: block;margin-bottom: 10px">支付方式</div>
                        <b-form-group  >
                            <b-form-radio  style="width: 40%;float: left;font-size: 0.7rem"  v-model='tradeType' value="MWEB"  name='tradeType'  size="sm" ><img style="width: 20px;height: 20px;margin-top: -4px" src='~@/assets/weixin@2x.png' />微信H5支付</b-form-radio>
                            <b-form-radio  style="width: 50%;float: left;font-size: 0.7rem"  v-model='tradeType' value="NOTIFY"  name='tradeType'  size="sm"><img style="width: 20px;height: 20px;margin-top: -4px" src='~@/assets/weixin@2x.png' />微信扫码支付</b-form-radio>
                        </b-form-group>

                        <!--<div class="mt-3">traderType: <strong>{{tradeType}}</strong></div>-->

                    </div>
                </b-col>
            </b-row>


            <b-row class="text-center"  style="display: block;margin-bottom: 5px;font-size: 0.8rem">
                <b-col  cols="12"  >
                    <div class="text-left text-muted bg-white"   v-if="type==1">
                        <span class="text-dark">月卡权益</span>
                        <br>
                        <small >.时间规划局将于每6：00发放24小时于您的时间机器;</small>
                        <br>
                        <small>.更多权益敬请期待;</small>
                    </div>
                    <div   v-if="type==2">
                        <b-form-checkbox  v-model="agreed" id="checkboxes-agreed"  >
                            <small>阅读<router-link   :to="{ name: 'Terms', params: { id: 5 }}"><b> 《自动续费服务协议》</b></router-link >
                                及
                                <router-link   :to="{ name: 'Terms', params: { id: 6 }}"><b>《充值服务协议》</b></router-link ></small>
                        </b-form-checkbox>
                    </div>
                </b-col>
            </b-row>

            <b-row class="text-center">
                <b-col>
                    <b-button variant="warning" size="lg" class="col-12 " @click="doPayment()">立即充值</b-button>
                    <br/>
                    <br/>
                </b-col>
            </b-row>

        </b-card>



    </b-container>
</template>

<script>
    import { requestWxRecharge,requestGetUserInfo,requestTransactionByTest,requestGetUserSpaceTime,requestTransactionByCord,requestTransactionByTime } from '../api/api';
    import {Message} from 'element-ui'

    export default {
        name: "",
        data() {
            return {
                show:true,
                agreed:true,
                loading: false,
                userInfo:null,
                spacetimeStr:null,
                spacetime:null,
                type:1,
                isActive: -1,
                transaction:{
                    cord:null,
                    time:null,
                    test:null
                },
                nameList: ["168小时","720小时","720小时","2160小时","8640小时","一个月","连续包月"],
                termsId:5,
                intervalId:null,
                tradeType:'MWEB'

            };
        },
        methods: {
            checkItemType(type,event){
                this.type=type
                var el = event.currentTarget;
                // console.log("当前对象的内容："+el.innerHTML);
            },
            checkItem(index,event){
                this.isActive=index;
                // var el = event.currentTarget;
                // console.log("index："+index);
                // console.log("当前对象的内容："+el.innerHTML);
            },
            onGoback() {
                //this.$router.go(-1);
                this.$router.push('/login');
            },
            doPayment(){
                let type = this.type
                let id =this.isActive
                let data =null
                if(type==1){
                    data=this.transaction.cord
                    Message.error({message:"该类型充值还未开放"});
                    return false

                }else if(type==2){
                    data=this.transaction.time
                }else if(type==3){
                    data=this.transaction.test
                }



                //let data = type==1?this.transaction.cord:this.transaction.time;
                console.log("data......."+JSON.stringify(data))
                let obj = {};
                obj = data.find((item)=>{ return item.id === id;});
                if(obj== undefined){
                    Message.error({message:"请先选中充值类型"});
                    return false
                }
                // else{
                //     Message.error({message: JSON.stringify(obj)});
                //
                // }
                // console.log("obj......."+JSON.stringify(obj))
                this.doWxRecharge(obj);

            },
            getTransactionByCord(){
                let transaction_cord  =sessionStorage.getItem("transaction_cord");
                if( transaction_cord!=null){
                    this.transaction.cord = JSON.parse(transaction_cord)
                    return true;
                }

                let _this=this
                this.loading = true;
                var params = { };

                requestTransactionByCord(params).then(data => {
                    this.loading = false;
                    console.log("data:.................")
                    console.log(JSON.stringify(data.msg))
                    if (data.code !== "1001") {
                        this.$message({
                            message: data.msg,
                            type: 'error'
                        });
                    } else {
                        data.data.transactionList.forEach(function(item,index) {
                            let nameList= ["168小时","720小时","2160小时","8640小时","一个月","连续包月"]
                            item.name = nameList[item.id-1]
                        });
                        this.transaction.cord = data.data.transactionList
                        sessionStorage.setItem("transaction-cord",JSON.stringify(this.transaction.cord) );

                        // console.log(JSON.stringify(this.userInfo))
                        // sessionStorage.setItem('userInfo',data.data);
                    }
                });
            },
            getTransactionByTime(){

                let transaction_time  =sessionStorage.getItem("transaction_time");
                if( transaction_time!=null){
                    this.transaction.time = JSON.parse(transaction_time)
                    return true;
                }

                let _this=this
                this.loading = true;
                var params = { };

                requestTransactionByTime(params).then(data => {
                    this.loading = false;
                    console.log("data:.................")
                    console.log(JSON.stringify(data.msg))
                    if (data.code !== "1001") {
                        this.$message({
                            message: data.msg,
                            type: 'error'
                        });
                    } else {
                        // this.userInfo = data.data
                        data.data.transactionList.forEach(function(item,index) {
                            let nameList= ["168小时","720小时","2160小时","8640小时","一个月","连续包月"]
                            item.name = nameList[item.id-1]
                        });
                        this.transaction.time = data.data.transactionList
                        sessionStorage.setItem("transaction-time",JSON.stringify(this.transaction.time) );
                        // console.log(JSON.stringify(this.userInfo))
                        // sessionStorage.setItem('userInfo',data.data);
                    }
                });
            },

            getTransactionByTest(){

                let transaction_time  =sessionStorage.getItem("transaction_test");
                if( transaction_time!=null){
                    this.transaction.time = JSON.parse(transaction_time)
                    return true;
                }

                let _this=this
                this.loading = true;
                var params = { };

                requestTransactionByTest(params).then(data => {
                    this.loading = false;
                    console.log("data:.................")
                    console.log(JSON.stringify(data.msg))
                    if (data.code !== "1001") {
                        this.$message({
                            message: data.msg,
                            type: 'error'
                        });
                    } else {
                        // this.userInfo = data.data
                        data.data.transactionList.forEach(function(item,index) {
                            item.name = "测试充值"
                        });
                        this.transaction.test = data.data.transactionList
                        sessionStorage.setItem("transaction-test",JSON.stringify(this.transaction.time) );
                    }
                });
            },

            getUserInfo(){
                let userInfoStr = sessionStorage.getItem("userInfo")
                if(userInfoStr!=null){
                    console.log(userInfoStr)
                    this.userInfo=JSON.parse(userInfoStr)
                    this.userInfo_time = this.getSpacetime(this.userInfo.spacetime)
                    console.log("userInfo_time"+this.userInfo_time)
                    return true
                }

                this.loading = true;
                var params = { };
                requestGetUserInfo(params).then(data => {
                    this.loading = false;
                    console.log("data:.................")
                    console.log(JSON.stringify(data.msg))
                    if (data.code !== "1001") {
                        this.$message({
                            message: data.msg,
                            type: 'error'
                        });
                    } else {
                        this.userInfo = data.data
                        console.log(JSON.stringify(this.userInfo ))
                        sessionStorage.setItem('userInfo',JSON.stringify(this.userInfo ));
                    }
                });

            },
            getUserSpacetime() {

                this.getUserInfo()
                this.loading = true;
                var params = {userId :this.userInfo.id };
                requestGetUserSpaceTime(params).then(data => {
                    this.loading = false;
                    console.log("data:.................")
                    console.log(JSON.stringify(data.msg))
                    if (data.code !== "1001") {
                        this.$message({
                            message: data.msg,
                            type: 'error'
                        });
                    } else {
                        this.spacetime = data.data
                        console.log(JSON.stringify(this.spacetime ))
                        sessionStorage.setItem('spacetime',data.data);
                        //this.spacetime = 100000000000
                        this.initIntervalId()
                    }
                });
            },
            doWxRecharge(productInfo) {
                this.loading = true;
                var params = {productId :productInfo.transactionId, price:productInfo.price,tradeType:this.tradeType};
                requestWxRecharge(params).then(data => {
                    this.loading = false;
                    // console.log("data:.................")
                    // console.log(JSON.stringify(data.msg))
                    if (data.code !== "1001") {
                        this.$message({
                            message: data.msg,
                            type: 'error'
                        });
                    } else {
                        sessionStorage.setItem('orderPayStr',JSON.stringify(data.data ));
                        this.$router.push('/order');

                    }
                });
            },


            checkTime(i){
                if(i<10)
                {
                    i = "0" + i;
                }
                return i;
            },
            getSpacetime(spacetime) {
                console.log(spacetime);
                if(spacetime<=0){
                    this.clearIntervalId()
                }
                if (spacetime == undefined || spacetime<=0) {

                    return "0年00月00日00时00分00秒";

                }
                var dataStr = "";

                //返回年-月-日-时-分-秒
                let tmpSpacetime=spacetime;
                var year = parseInt(spacetime / (86400000*360));
                tmpSpacetime =tmpSpacetime-year*(86400000*360)
                var moth = this.checkTime(parseInt(tmpSpacetime/ (86400000*30)));
                tmpSpacetime =tmpSpacetime -moth*(86400000*30)
                var day = this.checkTime(parseInt(tmpSpacetime / 86400000));
                var hour =  this.checkTime(parseInt((spacetime % 86400000) / 3600000));
                var minute =  this.checkTime(parseInt((spacetime % 86400000 % 3600000) / 60000));
                var second =  this.checkTime(parseInt((spacetime % 86400000 % 3600000 %60000) / 1000));
                // var msecond =  this.checkTime(parseInt((spacetime % 86400000 % 3600000 %60000 % 1000)/1));
                dataStr+=year+"年"+moth+"月"+day+"日"+hour+"时"+minute+"分"+second+"秒"
                return dataStr;

            },
            clearIntervalId(){
                if(this.intervalId!=null){
                    clearInterval(this.intervalId)
                }
            },
            initIntervalId(){
                this.clearIntervalId()
                this.intervalId=setInterval(()=>{
                    // console.log(this.spacetime)
                    this.spacetime =this.spacetime-1000*1
                    // console.log(this.spacetime)
                    this.spacetimeStr =this.getSpacetime(this.spacetime)
                    // console.log(this.spacetimeStr)
                },1000*1)
            }

        },
        mounted() {
            this.getTransactionByCord()
            this.getTransactionByTime()
            this.getTransactionByTest()
            this.getUserSpacetime()



        },
        beforeDestroy() {
            this.clearIntervalId()
        }
    }
</script>

<style >
    .tab-title-class >a{
        font-size: 0.8rem;
        color: lightgrey !important;

    }
    .tab-title-class >a:hover{
        font-size: 0.8rem;
        color: black !important;

    }
    .active{
        font-size: 0.8rem;
        color:black !important;

    }

</style>