<template>

    <div class="container-fluid" >
        <div class="query-header">
        </div>

        <div class="query-main" :style="{height:windowHeight-94+'px'}">
            <b-card  class="container bg-light col-11  " >
                <div class="text-left"  style="margin-left: -10px">
                    <b-form  @submit.stop.prevent    >
                        <b-form-group  id="input-group-name"
                                       label="姓   名:"
                                       label-for="input-name"
                                       label-cols="3"
                                       label-align="left"
                        :invalid-feedback="invalidNameback"
                        :state="nameState"
                                       description="" >
                            <!--:invalid-feedback="invalidNameback"-->
                            <!--:state="nameState"-->
                            <b-form-input
                                    id="input-name"
                                    v-model="queryInfo.name"
                                    type="text"
                                    required
                                    placeholder="请输入姓名"
                                    style="margin-left: -10px;margin-top: -5px"
                                    trim
                                    @click="onTdApp('输入姓名')"
                            >
                            </b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-identNumber"
                                      label="身份证:"
                                      label-for="input-identNumber"
                                      label-cols="3"
                                      label-align="left"
                        :invalid-feedback="invalidIdentNumberback"
                        :state="identNumberState"
                                      description="">
                            <!--:invalid-feedback="invalidIdentNumberback"-->
                            <!--:state="identNumberState"-->
                            <b-form-input
                                    id="input-identNumber"

                                    v-model="queryInfo.identNumber"
                                    type="text"
                                    required
                                    placeholder="请输入身份证号码"
                                    style="margin-left: -10px;margin-top: -5px"
                                    trim
                                    @click="onTdApp('输入身份证号码')"
                                     >
                            </b-form-input>
                        </b-form-group>


                        <b-form-group id="input-group-phone"
                                      label="手机号:"
                                      label-for="input-phone"
                                      label-cols="3"
                                      label-align="left"
                        :invalid-feedback="invalidPhoneback"
                        :state="phoneState"
                                      description="">
                            <!--:invalid-feedback="invalidPhoneback"-->
                            <!--:state="phoneState"-->
                            <b-form-input
                                    id="input-phone"
                                    v-model="queryInfo.phone"
                                    required
                                    placeholder="请输入手机号码"
                                    style="margin-left: -10px;margin-top: -5px"
                                    trim
                                    @click="onTdApp('输入手机号码')"

                            >
                            </b-form-input>
                        </b-form-group>


                        <b-form-group id="input-group-checked" label-cols="0" style="margin-top: -10px" >
                            <b-form-checkbox  v-model="agreed" id="checkboxes-agreed"  style="padding-top: 0.5rem" @change="onTdApp('点击同意条款')">
                                <small>我已阅读并同意<router-link   :to="{ name: 'Terms', params: { id: 0 }}"><b> 《报告查询协议》</b></router-link ></small>
                            </b-form-checkbox>
                        </b-form-group>

                    </b-form>
                </div>

                <b-button style="margin-left: -10px;margin-bottom: -10px" type="submit" size="lg" class="col-12 "  sm variant="primary" :loading="loading" @click="onQuery">一键查询</b-button>
            <!--</b-jumbotron>-->
            </b-card>

            <b-card  class="container bg-light col-11 mt-2 small" >
                <div class="text-left" style="margin-left: -10px;margin-top: -10px">
                    <h5>专业版评测</h5>
                </div>

                <div class="row" style="margin-left: -30px;margin-right: -30px;">
                    <div class="col-6 text-left text-muted  mt-2" > <div class="myicon1"></div>机构查询记录</div>
                    <div class="col-6 text-left text-muted  mt-2">  <div class="myicon2"></div>风险名单检测</div>
                </div>

                <div class="row" style="margin-left: -30px;margin-right: -30px">
                    <div class="col-6 text-left text-muted  mt-2">  <div class="myicon3"></div>历史逾期记录</div>
                    <div class="col-6 text-left text-muted  mt-2">  <div class="myicon4"></div>历史借贷行为</div>
                </div>

                <div class="row" style="margin-left: -30px;margin-right: -30px">
                    <div class="col-6 text-left text-muted  mt-2">  <div class="myicon5"></div>法院风险信息</div>
                    <div class="col-6 text-left text-muted  mt-2">  <div class="myicon6"></div>关联风险监测</div>
                </div>

            </b-card >

                <!--<span class="small mt-3"> Copyright ©2020-2029 天津软信科技有限公司</span>-->
                <span style="font-size:12px;">注：检查不查央行征信，填写信息仅用于检测</span> <span>  | <router-link   style="font-size:12px;color:#343a40"  :to="{ name: 'Contact', params: { id: 0 }}"> 联系我们</router-link ></span>

                 |<span style="font-size:12px;" @click="onShareClick">点击分享</span>
                <br/>
                <span style="font-size:12px;margin-bottom: 10px;"> Copyright ©2020-2029 天津软信科技有限公司  </span>
                <!--<span style="font-size:12px;margin-left: 3px;margin-bottom: 10px;">备案号：<a  href="http://beian.miit.gov.cn" target="_blank">津B2-20030024-11</a></span>-->
                <span style="font-size:12px;margin-left: 3px;margin-bottom: 10px;">备案号：<a  href="http://beian.miit.gov.cn" target="_blank">津B2-20030024-12</a></span>





        </div>



        <b-modal ref="paymentModal" title="支付" hide-footer centered>
            <div class="text-left"  >
                <div  class="text-left mb-3" >
                    <span class="text-left small" > 产品描述: <small class="ml-1"><b >个人报告</b></small></span>

                    <span class="ml-3 small"> 应该付金额:<b class="ml-1">{{price}}￥</b></span>
                </div>
                <h6 class='my-3 small'  >支付方式: <small class="ml-1"  v-if="tradeType!='4' && wxMobileFlag">请打开手机浏览器或电脑浏览器完成支付</small></h6>
                <b-form-group  v-if="tradeType!='4'">
                    <b-form-radio    style="margin-bottom:10px;width: 8rem;float: left;font-size: 0.7rem"  v-model='tradeType' value="2"  name='tradeType'  size="sm"><img style="width: 20px;height: 20px;margin-top: -4px" src='~@/assets/weixin@2x.png' />微信H5支付</b-form-radio>
                    <b-form-radio    style="margin-bottom:10px;width: 8rem;float: left;font-size: 0.7rem"  v-model='tradeType' value="3" name='tradeType'  size="sm" ><img style="width: 20px;height: 20px;margin-top: -4px" src='~@/assets/weixin@2x.png' />微信扫码支付</b-form-radio>
                    <b-form-radio    style="margin-bottom:10px;width: 8rem;float: left;font-size: 0.7rem"  v-model='tradeType' value="4" name='tradeType'  size="sm" ><img style="width: 20px;height: 20px;margin-top: -4px" src='~@/assets/weixin@2x.png' />微信JS支付</b-form-radio>

                    <!--<b-form-radio  style="margin-bottom:10px;width: 8rem;float: left;font-size: 0.7rem"  v-model='tradeType' value="6" name='tradeType'  size="sm" ><img style="width: 20px;height: 20px;margin-top: -4px" src='~@/assets/alipay.png' />支付宝扫码支付</b-form-radio>-->
                    <!--<b-form-radio  style="margin-bottom:10px;width: 8rem;float: left;font-size: 0.7rem"  v-model='tradeType' value="5" name='tradeType'  size="sm" ><img style="width: 20px;height: 20px;margin-top: -4px" src='~@/assets/alipay.png' />支付宝手机支付</b-form-radio>-->

                </b-form-group>
                <b-form-group   v-if="tradeType=='4'">
                    <b-form-radio    style="margin-bottom:10px;width: 8rem;float: left;font-size: 0.7rem"  v-model='tradeType' value="4" name='tradeType'  size="sm" ><img style="width: 20px;height: 20px;margin-top: -4px" src='~@/assets/weixin@2x.png' />微信支付</b-form-radio>
                </b-form-group>



            </div>

             <b-button variant="warning" size="lg" class="col-12 " @click="doPayment()">立即充值</b-button>
        </b-modal>


        <b-modal ref="tipModal" title="提示" >
            <div class="text-left"  >
                <div  class="text-left mb-3" >
                    <span class="text-left small" > 请通过手机浏览器或电脑浏览器打开该链接</span>

                </div>
            </div>
        </b-modal>

        <!--<wx-open-launch-weapp-->
                <!--id="launch-btn"-->
                <!--username="gh_xxxxxxxx"-->
                <!--path="pages/home/index?user=123&action=abc"-->
        <!--&gt;-->
            <!--<script type="text/wxtag-template">-->
                <!--<style>.btn { padding: 12px }</style>-->
                <!--<button class="btn">打开小程序 </button>-->
            <!--</script>-->
        <!--</wx-open-launch-weapp>-->


    </div>

</template>

<script>
    import { requesGetWxJsAPiParam,requesOauth2GetOpenid,requestRecharge } from '../api/api';
    import {Message} from 'element-ui'
    import wxapi from '../common/js/wxapi.js';

    export default {
        data() {
            return {
                mobileFlag: true,
                wxMobileFlag:false,
                loading: false,
                queryInfo: {
                    name: '',
                    identNumber: '',
                    phone: '',

                },
                tradeType:"",
                price:30.0,
                checked: false,
                agreed:false,
                show: true,
                windowHeight: document.documentElement.clientHeight,
                wxOpenId:""
            };
        },
        computed: {
            invalidNameback(){
                //this.onTdApp("输入姓名")
                return  this.nameState === true ?'':'请输入正确的姓名'
            },
            invalidPhoneback(){
                //this.onTdApp("输入手机号")
                return  this.phoneState === true ?'':'请输入正确的手机号'
            },
            invalidIdentNumberback(){
                //this.onTdApp("输入身份证号")
                return  this.identNumberState === true ?'':'请输入正确的身份号'
            },


            nameState(){
                let value=this.queryInfo.name;
                console.log("name:"+value)
                if(!value)return true
                const realnameReg = /^([a-zA-Z0-9\u4e00-\u9fa5]{1,10})$/;
                if (!realnameReg.test(value)){
                    return false;
                }
                return true;

            },
            phoneState(){
                let value=this.queryInfo.phone;
                if(!value)return true
                console.log("phone:"+value)
                const phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/;
                if (!phoneReg.test(value)){
                    return false;
                }
                return true;


            },
            identNumberState(){
                let value=this.queryInfo.identNumber;
                if(!value)return true
                console.log("identNumber:"+value)

                const idNumberReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
                if ( !idNumberReg.test(value)){
                    return false;
                }
                return true;

            },
        },
        methods: {
            isWxMobile() {
                let flag = window.navigator.userAgent.toLowerCase().indexOf("micromessenger")!==-1;
                return flag

            },
            isMobile() {
                let flag = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
                return flag;
            },
            onTdApp(id){
                // try{
                //     window.TDAPP.onEvent(id);
                // }catch (e) {
                //     console.log(e)
                // }
            },

            onQuery() {
                this.onTdApp("点击查询")
                //进行输入判断
                const realnameReg = /^([a-zA-Z0-9\u4e00-\u9fa5]{1,10})$/;
                const phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/;
                const idNumberReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;

                if(!this.queryInfo.name || !realnameReg.test(this.queryInfo.name)){
                    Message.error({message:"请输入正确姓名"});
                    return false
                }
                if(!this.queryInfo.identNumber || !idNumberReg.test(this.queryInfo.identNumber) ){
                    Message.error({message:"请输入正确身份证号"});
                    return false
                }if(  !this.queryInfo.phone|| !phoneReg.test(this.queryInfo.phone) ){
                    Message.error({message:"请输入正确手机号"});
                    return false
                }


                console.log("paymentModal")
                //先缓存用户的数据信息
                localStorage.setItem("queryInfo",JSON.stringify(this.queryInfo))

                if(!this.agreed){
                    Message.error({message:"请先阅读并同意《报告查询协议》"});
                    return false
                }

                this.$refs['paymentModal'].show()

            },
            doPayment(){
                //
                this.onTdApp("点击支付")
                console.log("doPayment.......")
                if(this.tradeType== undefined || this.tradeType==null || this.tradeType==""){
                    Message.error({message:"请先选支付方式"});
                    return false
                }
                this.onTdApp("生成订单")

                this.doRecharge();




            },

            doRecharge() {
                this.loading = true;
                var params = {productId :"credit_query", price:this.price,tradeType:this.tradeType,openId:this.wxOpenId};
                requestRecharge(params).then(data => {
                    this.loading = false;
                    // console.log("data:.................")
                    // console.log(JSON.stringify(data.msg))
                    if (data.code !== "1001") {
                        this.$message({
                            message: data.msg,
                            type: 'error'
                        });
                    } else {
                        localStorage.setItem('orderPayStr',JSON.stringify(data.data ));
                        this.$router.push('/order');

                    }
                });
            },
            doOauth2GetOpenid(code){
                this.loading = true;
                var params = {code :code};
                requesOauth2GetOpenid(params).then(data => {
                    this.loading = false;
                     console.log("doOauth2GetOpenid data:................."+data)
                     console.log(JSON.stringify(data))
                    if (data.code !== "1001") {
                        this.$message({
                            message: data.msg,
                            type: 'error'
                        });
                    } else {
                        this.tradeType="4"
                        this.wxOpenId=data.data
                        // this.wxOpenId = "o7bTm59sAoWL6iDCcfwq9UvgNhoI"
                        sessionStorage.setItem('wxOpenId',data.data );
                        console.log("localStorage save wxOpenId"+data.data)
                    }
                });


            },
            onShareClick(){
                let links  = location.href.split('#')[0]
                var params = {url :links };
                requesGetWxJsAPiParam(params).then(data => {
                    this.loading = false;
                    console.log("doGetWxJsAPiParam data:................."+data)
                    console.log(JSON.stringify(data))
                    if (data.code !== "1001") {
                        this.$message({
                            message: data.msg,
                            type: 'error'
                        });
                    } else {
                        let option = {
                            title: "信息查询", // 分享标题
                            link: links , // 分享链接
                            imgUrl: "https://www.credit-report.cn/img/query_header_logo.1b73540f.jpg", // 分享图标
                            desc: "", // 分享描述
                            success:function () {alert("ok")},
                            error:function () {alert("fail")}
                        };
                        wxapi.wxRegister(data.data,option);



                    }
                });


            },
            urlParse(){
                let obj = {};
                let reg = /[?&][^?&]+=[^?&%]+/g;
                let url = window.location.search;
                try{
                    let arr = url.match(reg);

                    arr.forEach((item) => {
                        let tempArr = item.substring(1).split('=');
                        let key = decodeURIComponent(tempArr[0]);
                        let val = decodeURIComponent(tempArr[1]);
                        obj[key] = val;
                    })
                }catch (e) {
                    //pass
                }

                return obj;
            }


        },
        mounted() {
            this.onTdApp("访问查询页面")

            if(this.isMobile()){
                this.mobileFlag=true;
            }else{
                this.mobileFlag=false;

            }
            if(this.isWxMobile()){
                this.wxMobileFlag=true
                console.log("start wx...................")
            }else{
                this.wxMobileFlag=false
                console.log("start not wx...................")
            }
            localStorage.removeItem('queryInfo');
            localStorage.removeItem('orderPayStr');
            //解析url 获取code 如果code非空就保存到本地


            console.log(window.location.href);
            var url = window.location.href;


            //let wxCode  = this.$route.query.code;
            let get  = this.urlParse()
            let wxCode = get.code


            let wxCodeTmp = sessionStorage.getItem("wxCode");
            console.log("start...................")
            console.log("wxCode-wxCodeTmp:" + wxCode+"-"+wxCodeTmp)

            if(wxCode!=null && wxCode!='' && wxCode!=undefined && wxCode!=wxCodeTmp){
                //localStorage.setItem('wxCode',wxCode );
                sessionStorage.setItem('wxCode',wxCode );
                console.log("wxCode" + wxCode)
                this.doOauth2GetOpenid(wxCode)
            }else{
                let wxOpenId = sessionStorage.getItem("wxOpenId");
                console.log("wxOpenId:" +wxOpenId)
                if(wxOpenId!=null && wxOpenId!='' && wxOpenId!=undefined){
                    console.log("wxOpenId" + wxOpenId)
                    this.wxOpenId=wxOpenId
                    this.tradeType="4"
                }
            }

            if(this.tradeType!=4 && this.wxMobileFlag){
                this.$refs['tipModal'].show()
            }

            this.onShareClick();
            // let queryInfoStr = localStorage.getItem("queryInfo");
            // console.log("queryInfoStr" + queryInfoStr)
            // if(queryInfoStr!=null && queryInfoStr!='' && queryInfoStr!=undefined){
            //     this.queryInfo = JSON.parse(queryInfoStr);
            // }

            // var btn = document.getElementById('launch-btn');
            // btn.addEventListener('launch', function (e) {
            //     console.log('success');
            // });
            // btn.addEventListener('error', function (e) {
            //     console.log('fail', e.detail);
            // });

        }

    }

</script>


<style>

    .query-header{
        position:fixed;top: 0; left: 0; width: 100%; z-index: 999;
        background: url('../assets/query_header_logo.jpg') no-repeat;
        background-size: 100% 100%;
        margin: 0 auto;
        height: 150px;
    }
    .query-main{
        position:fixed;top:150px; left: 0; width: 100%; z-index: 999;
        background: url('./../assets/query_main_bg.jpg') repeat-x;
        background-size: 100% 120%;
        margin: 0 auto;
        text-align: center;
        box-sizing: border-box;

    }
    .query-footer{
        position: fixed; bottom: 0; left: 0; width: 100%; z-index: 999;
        margin: 0 auto;
        height: auto;
        text-align: center;
    }


    .myicon1 {
        float: left;
        background:  url('./../assets/myicon.jpg') no-repeat -6px -10px;
        height: 32px;
        width: 30px;
        margin-right: 1rem;
        margin-top: -6px;
    }
    .myicon2 {
        float: left;
        background: url('./../assets/myicon.jpg') no-repeat -50px -10px;
        /*background-size: 100% 100%;*/
        height: 32px;
        width: 30px;
        margin-right: 1rem;
        margin-top: -6px;

    }
    .myicon3 {
        float: left;
        background: url('./../assets/myicon.jpg') no-repeat -6px -60px;
        /*background-size: 100% 100%;*/
        height: 32px;
        width: 30px;
        margin-right: 1rem;
        margin-top: -6px;
    }

    .myicon4 {
        float: left;
        background: url('./../assets/myicon.jpg') no-repeat -50px -60px;
        /*background-size: 100% 100%;*/
        height: 32px;
        width: 30px;
        margin-right: 1rem;
        margin-top: -6px;
    }

    .myicon5 {
        float: left;
        background: url('./../assets/myicon.jpg') no-repeat -6px -111px;
        /*background-size: 100% 100%;*/
        height: 32px;
        width: 30px;
        margin-right: 1rem;
        margin-top: -6px;
    }

    .myicon6 {
        float: left;
        background: url('./../assets/myicon.jpg') no-repeat -50px -111px;
        /*background-size: 100% 100%;*/
        height: 32px;
        width: 30px;
        margin-right: 1rem;
        margin-top: -6px;
    }
    .payment-modal > div {
        position: absolute;
        top: 300px;
        right: 100px;
        background-color: yellow;
    }


</style>
