<template>
  <b-container >

    <b-row  align-v="center"  >
      <b-col  class="py-0  " >

        <div class="bg-dark mb-5 "   style="width: 100%;margin: auto">
          <img src="~@/assets/login_logo.jpg"  style="width: 100%;height: 150px" alt="Fluid image">
        </div>


        <b-row>
          <b-col cols=8 offset="2" class="text-center">
            <b-form  @submit.stop.prevent  v-if="show"  >
              <b-form-group  id="input-group-phone"
                            label="phone:"
                            label-for="input-phone"
                            description=""
                             label-class="sr-only"

              >
                <b-form-input
                        id="input-phone"
                        v-model="loginInfo.phone"
                        type="text"
                        required
                        placeholder="Enter phone"
                        trim

                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-password"
                            label="password:"
                            label-for="input-password"
                            description=""
                            label-class="sr-only"
                            >
                <b-form-input
                        id="input-password"
                        v-model="loginInfo.password"
                        type="password"
                        required
                        placeholder="Enter password"
                        @keyup.enter.native="login"
                        trim

                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-checked">
                <b-form-checkbox  v-model="checked" id="checkboxes-checked" >
                  <small>记住密码</small>
                </b-form-checkbox>

                <b-form-checkbox  v-model="agreed" id="checkboxes-agreed"  style="font-size: 0.8rem;padding-top: 0.5rem">
                 <small>阅读<router-link   :to="{ name: 'Terms', params: { id: 3 }}"><b> 《隐私条款》</b>及</router-link ><router-link   :to="{ name: 'Terms', params: { id: 2 }}"><b>《用户协议》</b></router-link ></small>
                </b-form-checkbox>

              </b-form-group>


              <b-button type="submit" class="m-2"  sm variant="primary" :loading="loginLoading" @click="onLogin">登录</b-button>
              <b-button type="reset"  class="m-2" sm  variant="danger"  @click="onReset">重置</b-button>

            </b-form>
          </b-col>
        </b-row>

        <!--<div>-->
          <!--<b-link  class="m-2" :to="{ path: '/register'}">立即注册</b-link>-->

          <!--<b-link   class="m-2" :to="{ path: '/index'}" >前往首页</b-link>-->
        <!--</div>-->
      </b-col>
    </b-row>

  </b-container>

</template>

<script>
  import { requestLogin,setToken } from '../api/api';
  import wxapi from '../common/js/wxapi.js';
  import { MD5 } from '../common/js/MD5'
  //import NProgress from 'nprogress'
  export default {
    data() {
      return {
          loginLoading: false,
          loginInfo: {
              phone: '',
              password: '',
          },
          checked: true,
          agreed:true,
          show: true,
      };
    },
    methods: {
        onReset(evt) {
            evt.preventDefault()
            // Reset our form values
            this.loginInfo.phone = ''
            this.loginInfo.password = ''
            this.checked = false
            this.agreed=false
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        },
        onLogin() {
           this.loginLoading = true;
           var loginParams = { phone: this.loginInfo.phone, password: MD5(this.loginInfo.password+"spacetime"),rememberMe:this.checked,platform:"windows"};
           requestLogin(loginParams).then(data => {
               this.loginLoading = false;
               console.log("data:.................")
               console.log(JSON.stringify(data.msg))
               if (data.code !== "1001") {
                  this.$message({
                    message: data.msg,
                    type: 'error'
                  });
               } else {
                   setToken(data.data.token)
                   sessionStorage.setItem("userInfo",JSON.stringify(data.data))
                   this.$router.push('/userInfo');
               }
            });
        },

    }
  }

</script>


<style>

</style>
