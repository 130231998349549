<template>
    <div class="container-fluid px-0 mx-0">
        <div class="wrap-header row  ">
            <b-col class="text-white" cols="1"  >
            <b-icon icon="chevron-left "  style="width: 25px; height: 25px;margin-top: 3rem"  @click="onGoback"></b-icon>
            </b-col>
            <b-col class="text-center text-white" cols="10"  style="margin-top: 3rem">
            <h5><b>专业版测评</b>  </h5>
            <small>  <b>报告查询时间   {{ nowformatTime }} </b></small>
            </b-col>
        </div>
        <div class="wrap-main bg-muted text-left" >
            <div class="col-12 mx-1"  v-if="show">

                <b-table-simple  caption-top borderless outlined img-rounded
                                 class="bg-white my-3 text-muted py-3"
                                 style="border-collapse: separate;border-spacing: 0;border-radius: 10px;">
                    <caption><b text-muted>基本信息</b></caption>
                    <b-tbody v-if="creditInfo.resp_data.base_info">
                        <b-tr class="row col-12">
                            <b-td  class="col-4 text-left text-muted" >姓名</b-td>
                            <b-td  class="col-8 text-right" >{{creditInfo.resp_data.base_info.name}}</b-td>
                        </b-tr>
                        <b-tr  class="row col-12">
                            <b-td  class="col-4 text-left text-muted" >手机号</b-td>
                            <b-td  class="col-8 text-right" >{{creditInfo.resp_data.base_info.phone}}</b-td>
                        </b-tr>
                        <b-tr  class="row col-12">
                            <b-td  class="col-4 text-left text-muted" >身份证号</b-td>
                            <b-td  class="col-8 text-right" >{{creditInfo.resp_data.base_info.ident_number}}</b-td>
                        </b-tr>
                        <b-tr  class="row col-12">
                            <b-td  class="col-4 text-left text-muted" >报告编号</b-td>
                            <b-td class="col-8 text-right"  >{{creditInfo.resp_order}}</b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tbody v-else>
                        <b-tr  class="row col-12">
                            <td class="col-12 text-left text-muted"> 暂无记录</td>
                        </b-tr>
                    </b-tbody>

                </b-table-simple>

                <b-table-simple  caption-top borderless outlined img-rounded
                                 class="bg-white my-3 text-muted py-3"
                                 style="border-collapse: separate;border-spacing: 0;border-radius: 10px;">
                    <caption><b text-muted>信用分</b></caption>
                    <b-tbody  v-if="creditInfo.resp_data.score_norm_explain">
                        <b-tr>
                            <b-td  class="text-left" >

                                <div class="row col-12 mt-2  text-left">
                                    <span  style="font-weight:normal;font-size: 14px;color: black"> 您的信用风险分：</span>
                                    <span :class="{'text-success font-weight-bold': multipleScoreColor==3,'text-warning font-weight-bold': multipleScoreColor==2, 'text-danger font-weight-bold': multipleScoreColor==1,  }" style="font-size: 30px;" >{{creditInfo.resp_data.score_norm_explain}}</span>
                                </div>




                                <div class="row mt-2 text-muted ">
                                    <span class="col-4 text-center">建议拒绝</span><span class="col-4 text-center">人工审核</span><span class="col-4 text-center">建议通过 </span>
                                </div>

                                <b-progress  height="10px" class="mt-2 " >
                                    <b-progress-bar :value="100/3" variant="danger"></b-progress-bar>
                                    <b-progress-bar :value="100/3" variant="warning"></b-progress-bar>
                                    <b-progress-bar :value="100/3" variant="success"></b-progress-bar>
                                </b-progress>

                                <div class="row mt-2 text-muted " style="position: relative">
                                    <span style="position:absolute" class="col-12 top text-left  ">0</span>
                                    <span style="position:absolute" class="col-4 ml-4  top text-right">450</span>
                                    <span style="position:absolute" class="col-8 ml-3  top text-right ">530</span>
                                    <span style="position:absolute" class="col-12 top text-right">650</span>

                                    <span  class="col-12 mt-4 text-center"  >注：得分越低，风险越高</span>
                                </div>

                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tbody v-else>
                        <b-tr  class="row col-12">
                            <td class="col-12 text-left text-muted"> 暂无记录</td>
                        </b-tr>
                    </b-tbody>

                </b-table-simple>


                <b-table-simple  caption-top borderless outlined img-rounded
                                 class="bg-white my-3 text-muted py-3"
                                 style="border-collapse: separate;border-spacing: 0;border-radius: 10px;">
                    <caption><b text-muted>信用分</b></caption>
                    <b-tbody v-if="creditInfo.resp_data.verify_recomment">
                        <b-tr>
                            <b-td  class="text-left" >

                                <div class="row mt-2 text-muted text-left ">
                                    <div class="col-12">
                                        类型标签：
                                        <span :class="{'text-success font-weight-bold': multipleScoreColor==3,'text-warning font-weight-bold': multipleScoreColor==2, 'text-danger font-weight-bold': multipleScoreColor==1,  }" style="font-size: 14px;">{{creditInfo.resp_data.verify_recomment}}</span>

                                        <br/>
                                        命中风险标注：

                                        <div v-if="creditInfo.resp_data.hit_risk_tagging!=''">
                                            <div v-for="(itme,index) in creditInfo.resp_data.hit_risk_tagging" :key="index">
                                                <span :class="{'text-danger  font-weight-bold': multipleScoreColor==1,'text-warning font-weight-bold': multipleScoreColor==2, 'text-success font-weight-bold': multipleScoreColor==3,  }" style="font-size: 14px;" >{{index+1}}、{{itme}} </span>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <span :class="{'text-success font-weight-bold': multipleScoreColor==1,'text-warning font-weight-bold': multipleScoreColor==2, 'text-danger font-weight-bold': multipleScoreColor==3,  }" style="font-size: 14px;" >无记录 </span>
                                        </div>



                                    </div>
                                </div>


                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tbody v-else>
                        <b-tr  class="row col-12">
                            <td class="col-12 text-left text-muted"> 暂无记录</td>
                        </b-tr>
                    </b-tbody>

                </b-table-simple>

                <b-table-simple  caption-top borderless outlined img-rounded
                                 class="bg-white my-3 text-muted py-3"
                                 style="border-collapse: separate;border-spacing: 0;border-radius: 10px;">
                    <caption><b text-muted>机构查询记录</b></caption>
                    <b-tbody v-if="creditInfo.resp_data.personal_loan_demand">
                        <b-tr>
                            <b-td class="col-12 text-left">

                                <b-button pill variant="outline-primary" size="sm"    class=" mr-1"  @click="setWxDatad(7)">近7天</b-button>
                                <b-button pill variant="outline-primary" size="sm" class="mr-1"  @click="setWxDatad(1)">近30天</b-button>
                                <b-button pill variant="outline-primary" size="sm" class=" mr-1"  @click="setWxDatad(3)">近90天</b-button>

                            </b-td>
                        </b-tr>

                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-6 text-left" ><b>机构名称</b></b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right"><b>查询次数</b></b-td>
                        </b-tr>

                        <b-tr class="row col-12 mt-3 ">
                            <b-td  class="col-6 text-left text-muted">一般消费分期平台</b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right">{{ wxDatad.common_amortize_platform }}</b-td>
                        </b-tr>
                        <b-tr class="row col-12 mt-3 ">
                            <b-td  class="col-6 text-left text-muted" >大型消费金融公司</b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right">{{ wxDatad.large_finance_platform }}</b-td>
                        </b-tr>
                        <b-tr class="row col-12 mt-3 ">
                            <b-td  class="col-6 text-left text-muted" >其它类型贷款公司</b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right">{{ wxDatad.other_platform }}</b-td>
                        </b-tr>
                        <b-tr class="row col-12 mt-3 ">
                            <b-td  class="col-6 text-left text-muted" >小额贷款公司</b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right">{{ wxDatad.petty_loan_platform }}</b-td>
                        </b-tr>


                        <b-tr class="row col-12 mt-3 ">
                            <b-td  class="col-6 text-left text-muted" >P2P平台</b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right">{{ wxDatad.p2p_platform }}</b-td>
                        </b-tr>

                        <b-tr class="row col-12 mt-3 ">
                            <b-td  class="col-6 text-left text-muted" >信用卡</b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right">{{ wxDatad.credit_card }}</b-td>
                        </b-tr>

                        <b-tr class="row col-12 mt-3 ">
                            <b-td  class="col-6 text-left text-muted" >申请查询总数</b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right">{{ wxDatad.platform_total }}</b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tbody v-else>
                        <b-tr  class="row col-12">
                            <td class="col-12 text-left text-muted"> 暂无记录</td>
                        </b-tr>
                    </b-tbody>

                </b-table-simple>


                <b-table-simple  caption-top borderless outlined img-rounded
                                 class="bg-white my-3 text-muted py-3"
                                 style="border-collapse: separate;border-spacing: 0;border-radius: 10px;">
                    <caption><b text-muted>历史借贷行为</b></caption>
                    <b-tbody  v-if="creditInfo.resp_data.personal_loan_s">

                        <b-tr class="row col-12 mt-0 mb-2">
                                <b-td  class="col-12 text-left" ><b>最近12个月申请机构查询</b></b-td>

                        </b-tr>

                        <b-tr>
                            <b-td class="col-12 text-left">
                                <b-button-group>
                                    <b-button pill variant="outline-primary" size="sm"    class=" mr-1"  @click="setApplyMechanismNumber(1)">近1个月</b-button>
                                    <b-button pill variant="outline-primary" size="sm" class="mr-1"  @click="setApplyMechanismNumber(3)">近3个月</b-button>
                                    <b-button pill variant="outline-primary" size="sm" class=" mr-1"  @click="setApplyMechanismNumber(6)">近6个月</b-button>
                                    <b-button pill variant="outline-primary" size="sm" class=" mr-1" @click="setApplyMechanismNumber(12)">近12个月</b-button>
                                </b-button-group>
                            </b-td>
                        </b-tr>


                        <b-tr class="row col-12 mt-2 ">
                            <b-td  class="col-6 text-left text-muted" >申请总数</b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right">{{applyMechanismNumber}}</b-td>
                        </b-tr>

                        <b-tr class="row col-12 ">
                            <b-td  class="col-12  text-muted" >
                                <hr>
                            </b-td>
                        </b-tr>

                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-6 text-left" ><b>机构类型</b></b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right"><b>申请个数</b></b-td>
                        </b-tr>

                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-6 text-left text-muted">消费分期类</b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right">{{creditInfo.resp_data.personal_loan_s.consumer_apply_mechanism_number}}</b-td>
                        </b-tr>
                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-6 text-left text-muted" >网络贷款类</b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right">{{creditInfo.resp_data.personal_loan_s.network_loan_apply_mechanis_mnumber}}</b-td>
                        </b-tr>


                        <b-tr class="row col-12 ">
                            <b-td  class="col-12  text-muted" >
                                <hr>
                            </b-td>
                        </b-tr>

                        <b-tr class="row col-12 ">
                            <b-td  class="col-8 text-left text-muted" >最后一次申请日期</b-td>
                            <b-td  class="col-4 text-right">{{creditInfo.resp_data.personal_loan_s.last_apply_time}}</b-td>

                        </b-tr>
                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-9 text-left text-muted" >距离最近一次申请已有（天）</b-td>
                            <b-td  class="col-3 text-right">{{creditInfo.resp_data.personal_loan_s.last_apply_time_distance}}</b-td>
                        </b-tr>
                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-9 text-left text-muted" >近12个月申请机构总数</b-td>
                            <b-td  class="col-3 text-right">{{creditInfo.resp_data.personal_loan_s.apply_mechanism_number}}</b-td>
                        </b-tr>

                    </b-tbody>
                    <b-tbody v-else>
                        <b-tr  class="row col-12">
                            <td class="col-12 text-left text-muted"> 暂无记录</td>
                        </b-tr>
                    </b-tbody>

                </b-table-simple>

                <b-table-simple  caption-top borderless outlined img-rounded
                                 class="bg-white my-3 text-muted py-3"
                                 style="border-collapse: separate;border-spacing: 0;border-radius: 10px;">
                    <b-tbody v-if="creditInfo.resp_data.personal_loan_f">

                        <b-tr class="row col-12 mt-0 mb-2">
                            <b-td  class="col-12 text-left" ><b>最近12个月放款机构查询</b></b-td>

                        </b-tr>

                        <b-tr>
                            <b-td class="col-12 text-left">
                                <b-button-group>
                                    <b-button pill variant="outline-primary" size="sm"    class=" mr-1"  @click="setLendNumber(1)">近1个月</b-button>
                                    <b-button pill variant="outline-primary" size="sm" class="mr-1"  @click="setLendNumber(3)">近3个月</b-button>
                                    <b-button pill variant="outline-primary" size="sm" class=" mr-1"  @click="setLendNumber(6)">近6个月</b-button>
                                    <b-button pill variant="outline-primary" size="sm" class=" mr-1" @click="setLendNumber(12)">近12个月</b-button>
                                </b-button-group>
                            </b-td>
                        </b-tr>

                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-6 text-left text-muted" >申请总数</b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right">{{lendNumber}}</b-td>
                        </b-tr>

                        <b-tr class="row col-12 ">
                            <b-td  class="col-12  text-muted" >
                                <hr>
                            </b-td>
                        </b-tr>

                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-6 text-left" ><b>机构类型</b></b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right"><b>放款次数</b></b-td>
                        </b-tr>

                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-6 text-left text-muted">消费分期类</b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right">{{creditInfo.resp_data.personal_loan_f.cflenders}}</b-td>
                        </b-tr>
                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-6 text-left text-muted" >网络贷款类</b-td>
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-4 text-right">{{creditInfo.resp_data.personal_loan_f.nllenders}}</b-td>
                        </b-tr>


                        <b-tr class="row col-12 ">
                            <b-td  class="col-12  text-muted" >
                                <hr>
                            </b-td>
                        </b-tr>

                        <b-tr class="row col-12 ">
                            <b-td  class="col-7 text-left text-muted" >最后一次放款日期</b-td>
                            <b-td  class="col-5 text-right"  >{{creditInfo.resp_data.personal_loan_f.lend_time}}</b-td>

                        </b-tr>
                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-9 text-left text-muted" >距离最近一次放款已有（天）</b-td>
                            <b-td  class="col-3 text-right" >{{creditInfo.resp_data.personal_loan_f.lend_time_distance}}</b-td>
                        </b-tr>

                        <b-tr class="row col-12 ">
                            <b-td  class="col-12  text-muted" >
                                <hr>
                            </b-td>
                        </b-tr>

                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-2"></b-td>
                            <b-td  class="col-6 text-right" ><b>近一个月</b></b-td>
                            <b-td  class="col-4 text-right"><b>近12个月</b></b-td>
                        </b-tr>


                        <b-tr class="row col-12 ">
                            <b-td  class="col-5 text-left text-muted" >履约放款</b-td>
                            <b-td  class="col-3 text-right">{{creditInfo.resp_data.personal_loan_f.repay_succ1}}</b-td>
                            <b-td  class="col-4 text-right">{{creditInfo.resp_data.personal_loan_f.repay_succ12}}</b-td>

                        </b-tr>
                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-5 text-left text-muted" >还款异常次数</b-td>
                            <b-td  class="col-3 text-right">{{creditInfo.resp_data.personal_loan_f.repay_fail1}}</b-td>
                            <b-td  class="col-4 text-right">{{creditInfo.resp_data.personal_loan_f.repay_fail12}}</b-td>
                        </b-tr>

                    </b-tbody>
                    <b-tbody v-else>
                        <b-tr  class="row col-12">
                            <td class="col-12 text-left text-muted"> 暂无记录</td>
                        </b-tr>
                    </b-tbody>

                </b-table-simple>

                <b-table-simple  caption-top borderless outlined img-rounded
                                 class="bg-white my-3 text-muted py-3"
                                 style="border-collapse: separate;border-spacing: 0;border-radius: 10px;">
                    <caption><b text-muted>历史逾期记录</b></caption>
                    <b-tbody v-if="creditInfo.resp_data.personal_overdue_history">
                        <b-tr class="row col-12 mt-0 mb-2">
                            <b-td  class="col-12 text-left" ><b>最近12个月申请机构查询</b></b-td>

                        </b-tr>
                        <b-tr class="row col-12 ">
                            <b-td  class="col-7 text-left text-muted" >近6个月逾期机构次数</b-td>
                            <b-td  class="col-5 text-right">{{creditInfo.resp_data.personal_overdue_history.overdue_mechanism_number}}</b-td>

                        </b-tr>
                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-8 text-left text-muted" >近6个月逾期总次数</b-td>
                            <b-td  class="col-4 text-right">{{creditInfo.resp_data.personal_overdue_history.counts}}</b-td>
                        </b-tr>
                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-8 text-left text-muted" >近6个月未结清逾期次数</b-td>
                            <b-td  class="col-4 text-right">{{creditInfo.resp_data.personal_overdue_history.uncleared_counts}}</b-td>
                        </b-tr>
                        <b-tr class="row col-12 mt-1 ">
                            <b-td  class="col-8 text-left text-muted" >近6个月逾期总金额（元）</b-td>
                            <b-td  class="col-4 text-right">{{creditInfo.resp_data.personal_overdue_history.overdue_money}}</b-td>
                        </b-tr>

                        <b-tr class="row col-12 ">
                            <b-td  class="col-12  text-muted" >
                                <hr>
                            </b-td>
                        </b-tr>



                    </b-tbody>
                    <b-tbody v-else>
                        <b-tr  class="row col-12">
                            <td class="col-12 text-left text-muted"> 暂无记录</td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>

                <b-table-simple  caption-top borderless outlined img-rounded
                                     class="bg-white my-3 text-muted py-3"
                                     style="border-collapse: separate;border-spacing: 0;border-radius: 10px;">
                        <caption><b text-muted>关联风险检测</b></caption>
                        <b-tbody  v-if="creditInfo.resp_data.relevance_risk_check">

                            <b-tr class="row col-12 ">
                                <b-td  class="col-10 text-left text-muted" >3个月身份证关联手机号数（个）</b-td>
                                <b-td  class="col-2 text-right">{{creditInfo.resp_data.relevance_risk_check.m3_idcard_to_phone_time}}</b-td>

                            </b-tr>
                            <b-tr class="row col-12 mt-1 ">
                                <b-td  class="col-10 text-left text-muted" >3个月手机号关联身份证数（个）</b-td>
                                <b-td  class="col-2 text-right">{{creditInfo.resp_data.relevance_risk_check.m3_phone_to_idcard_time}}</b-td>
                            </b-tr>


                        </b-tbody>
                        <b-tbody v-else>
                            <b-tr  class="row col-12">
                                <td class="col-12 text-left text-muted"> 暂无记录</td>
                            </b-tr>
                        </b-tbody>
                </b-table-simple>


                <b-table-simple  caption-top borderless outlined img-rounded
                                 class="bg-white my-3 text-muted py-3"
                                 style="border-collapse: separate;border-spacing: 0;border-radius: 10px;">
                    <caption><b text-muted>法院风险信息</b></caption>
                    <b-tbody v-if="creditInfo.resp_data.court_risk_info_list">
                        <b-tr class="row col-12 mt-1 ">
                            <b-td class="col-12  text-muted"  v-if="creditInfo.resp_data.court_risk_info_list.length>0">
                                <div :style="{ 'width': windowWidth-78 + 'px','overflow-x' : 'scroll' }"   class="scroll-bar" >
                                    <b-table  striped hover :items="creditInfo.resp_data.court_risk_info_list" :fields="courtRiskInfoListFields"
                                              style="min-width:700px; "

                                    >
                                        <template v-slot:table-colgroup="scope">
                                            <col
                                                    v-for="field in scope.fields"
                                                    :key="field.key"
                                                    :style="{ width: 'auto',color:'gray' }"
                                            >
                                        </template>

                                        <template v-slot:cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>



                                    </b-table>
                                </div>



                            </b-td>

                            <td class="col-12 text-left text-muted" v-else> 暂无记录</td>
                        </b-tr>


                    </b-tbody>
                    <b-tbody v-else>
                        <b-tr  class="row col-12">
                            <td class="col-12 text-left text-muted"> 暂无记录</td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>


            </div>
            <div class="col-12 mx-1"  v-else>
                <div  class="row  text-center bg-white "  :style="{height:windowHeight-120+'px'}">
                    <span class="col-12 text-danger m-auto"  ><h4>{{ queryErrorMsg}}</h4></span>
                </div>


            </div>


        </div>





    </div>
</template>

<script>

    import util from '../api/util';
    import { requestCreditQuery,requestWxQueryOrder,requestWxCloseOrder,requestGetUserInfo,requestGetUserSpaceTime,requestTransactionByCord,requestTransactionByTime } from '../api/api';
    import {Message} from 'element-ui'

    export default {
        name: "",
        data() {
            return {
                show: false,
                tipShow: true,
                loading: false,
                orderNo:null,
                qrCode:null,
                description:null,
                amount:0,
                status:null,
                statusDesc:"",
                confirmFlg:false,
                queryInfo: {
                    name: '',
                    identNumber: '',
                    phone: '',
                },
                wxDatad: {
                    common_amortize_platform: '0',
                    large_finance_platform: '0',
                    other_platform: '0',
                    petty_loan_platform:'0',
                    p2p_platform:'0',
                    credit_card:'0',
                    platform_total:'0'
                },
                nowformatTime:"",
                creditInfo:null,
                multipleScoreColor:1,
                applyMechanismNumber:"无记录数",

                lendNumber:"无记录数",

                personalOverdueHistoryDataListFields:[

                    { key: 'index', label:'序号' },
                    { key: 'overdue_day', label: '逾期天数 (S:单期 M:多期)' },
                    { key: 'overdue_money', label: '逾期金额区间（元）' },
                    { key: 'overdue_time', label: '逾期时间' },
                    { key: 'settlement', label: '是否结清' },
                ],
                courtRiskInfoListFields:[
                    { key: 'index', label:'序号' },
                    { key: 'sort_time_string', label: '审结日期' },
                    { key: 'data_type', label: '类型' },
                    { key: 'summary', label: '摘要说明' },
                    { key: 'compatibility', label: '匹配度' },
                ],
                windowWidth: document.documentElement.clientWidth,
                windowHeight: document.documentElement.clientHeight,
                queryErrorMsg:"无记录"
            };
        },
        methods: {

            setWxDatad(type){
                let t=type
                if(t==7){
                    this.wxDatad.common_amortize_platform= this.creditInfo.resp_data.personal_loan_demand.d7_apply_setup_time_ybxffq;
                    this.wxDatad.large_finance_platform=this.creditInfo.resp_data.personal_loan_demand.d7_apply_setup_time_dxxfjr;
                    this.wxDatad.other_platform=this.creditInfo.resp_data.personal_loan_demand.d7_apply_setup_time_other;
                    this.wxDatad.petty_loan_platform=this.creditInfo.resp_data.personal_loan_demand.d7_apply_setup_time_xedkgs;
                    this.wxDatad.p2p_platform=this.creditInfo.resp_data.personal_loan_demand.d7_apply_setup_time_p2pwd;
                    this.wxDatad.credit_card=this.creditInfo.resp_data.personal_loan_demand.d7_apply_setup_time_xyk;
                    this.wxDatad.platform_total=this.creditInfo.resp_data.personal_loan_demand.d7_apply_setup_time;
                }else if(t==1){
                    this.wxDatad.common_amortize_platform= this.creditInfo.resp_data.personal_loan_demand.m1_apply_setup_time_ybxffq;
                    this.wxDatad.large_finance_platform=this.creditInfo.resp_data.personal_loan_demand.m1_apply_setup_time_dxxfjr;
                    this.wxDatad.other_platform=this.creditInfo.resp_data.personal_loan_demand.m1_apply_setup_time_other;
                    this.wxDatad.petty_loan_platform=this.creditInfo.resp_data.personal_loan_demand.m1_apply_setup_time_xedkgs;
                    this.wxDatad.p2p_platform=this.creditInfo.resp_data.personal_loan_demand.m1_apply_setup_time_p2pwd;
                    this.wxDatad.credit_card=this.creditInfo.resp_data.personal_loan_demand.m1_apply_setup_time_xyk;
                    this.wxDatad.platform_total=this.creditInfo.resp_data.personal_loan_demand.m1_apply_setup_time;
                }else if(t==3){
                    this.wxDatad.common_amortize_platform= this.creditInfo.resp_data.personal_loan_demand.m3_apply_setup_time_ybxffq;
                    this.wxDatad.large_finance_platform=this.creditInfo.resp_data.personal_loan_demand.m3_apply_setup_time_dxxfjr;
                    this.wxDatad.other_platform=this.creditInfo.resp_data.personal_loan_demand.m3_apply_setup_time_other;
                    this.wxDatad.petty_loan_platform=this.creditInfo.resp_data.personal_loan_demand.m3_apply_setup_time_xedkgs;
                    this.wxDatad.p2p_platform=this.creditInfo.resp_data.personal_loan_demand.m3_apply_setup_time_p2pwd;
                    this.wxDatad.credit_card=this.creditInfo.resp_data.personal_loan_demand.m3_apply_setup_time_xyk;
                    this.wxDatad.platform_total=this.creditInfo.resp_data.personal_loan_demand.m3_apply_setup_time;
                }else{
                    this.wxDatad="无记录"
                }
            },
            setApplyMechanismNumber(type){
                let t=type
                if(t==1){
                    this.applyMechanismNumber = this.creditInfo.resp_data.personal_loan_s.apply_time1
                }else if(t==3){
                    this.applyMechanismNumber = this.creditInfo.resp_data.personal_loan_s.apply_time3
                }else if(t==6){
                    this.applyMechanismNumber = this.creditInfo.resp_data.personal_loan_s.apply_time6
                }else if(t==12){
                    this.applyMechanismNumber = this.creditInfo.resp_data.personal_loan_s.apply_time12
                }else{
                    this.applyMechanismNumber="无记录"
                }
            },
            setLendNumber(type){
                let t=type
                if(t==1){
                    this.lendNumber = this.creditInfo.resp_data.personal_loan_f.lend_number1
                }else if(t==3){
                    this.lendNumber = this.creditInfo.resp_data.personal_loan_f.lend_number3
                }else if(t==6){
                    this.lendNumber = this.creditInfo.resp_data.personal_loan_f.lend_number6
                }else if(t==12){
                    this.lendNumber = this.creditInfo.resp_data.personal_loan_f.lend_number12
                }else if(t==24){
                    this.lendNumber = this.creditInfo.resp_data.personal_loan_f.lend_number24
                }else{
                    this.lendNumber="无记录"
                }
            },
            getMultipleScoreColor:function(){
                this.multipleScoreColor=1;
                if(this.creditInfo.resp_data.score_norm_explain!=null && this.creditInfo.resp_data.score_norm_explain >= 0){
                    if( this.creditInfo.resp_data.score_norm_explain >= 530) this.multipleScoreColor=3
                    else if( this.creditInfo.resp_data.score_norm_explain >= 450) this.multipleScoreColor=2;
                    else  this.multipleScoreColor=1;
                }


            },
            getformatTime () {
                console.log("--------------------------------")
                let dateStr = util.formatDate.format(new Date(),'yyyy-MM-dd hh:mm:s ')
                console.log(dateStr)
                return dateStr
            },

            onGoback() {
                // this.$router.go(-1);
                this.$router.push('/');
            },


        },
        mounted() {
            this.nowformatTime = this.getformatTime()

            console.log("nowformatTime"+this.nowformatTime )

            let creditJsonStr = localStorage.getItem("creditJson");
            let creditJson=JSON.parse(creditJsonStr);


            console.log("creditJson"+creditJson )
            this.creditInfo = JSON.parse(creditJson);
            console.log(this.creditInfo.resp_code)

            // this.creditInfo={
            //     "resp_code": "SW0000",
            //     "resp_msg": "查询成功",
            //     "timestamp": 1715740524365,
            //     "resp_order": "lgt17157405223412",
            //     "resp_data": {
            //         "verify_recomment": "建议通过",
            //         "suggest_cash": "",
            //         "score_norm_explain": 555,
            //         "base_info": {
            //             "total_periods": -1,
            //             "gender": "男",
            //             "total_rent": -1,
            //             "ident_number_address": "广西壮族自治区梧州市岑溪市",
            //             "phone": "13471096345",
            //             "price": -1,
            //             "name": "蒋昌霖",
            //             "goods_type": -1,
            //             "ident_number": "450481199409100635",
            //             "strategy_code": "CL03b7224",
            //             "phone_address": "广西壮族自治区崇左市",
            //             "age": 29,
            //             "status": -1
            //         },
            //         "court_risk_info_list": [],
            //         "personal_loan_s": {
            //             "last_apply_time": "2023-10",
            //             "last_apply_time_distance": "(180, 240)",
            //             "apply_time6": "0",
            //             "apply_time12": "14",
            //             "apply_time1": "0",
            //             "network_loan_apply_mechanis_mnumber": "0",
            //             "apply_mechanism_number": "12",
            //             "consumer_apply_mechanism_number": "8",
            //             "apply_time3": "0"
            //         },
            //         "personal_overdue_history": {
            //             "overdue_mechanism_number": 0,
            //             "datalist": [],
            //             "counts": 0,
            //             "uncleared_counts": 0,
            //             "overdue_money": ""
            //         },
            //         "relevance_risk_check": {
            //             "m3_idcard_to_phone_time": 0,
            //             "m3_phone_to_idcard_time": 0
            //         },
            //         "personal_loan_demand": {
            //             "m3_apply_setup_time": 0,
            //             "m1_apply_setup_time": 0,
            //             "m3_apply_setup_time_ybxffq": 0,
            //             "m1_apply_setup_time_dxxfjr": 0,
            //             "m1_apply_setup_time_xyk": 0,
            //             "d7_apply_setup_time_xedkgs": 0,
            //             "d7_apply_setup_time": 0,
            //             "m3_apply_setup_time_xyk": 0,
            //             "m1_apply_setup_time_ybxffq": 0,
            //             "d7_apply_setup_time_xyk": 0,
            //             "m3_apply_setup_time_xedkgs": 0,
            //             "m1_apply_setup_time_xedkgs": 0,
            //             "m1_apply_setup_time_p2pwd": 0,
            //             "m3_apply_setup_time_other": 0,
            //             "d7_apply_setup_time_p2pwd": 0,
            //             "d7_apply_setup_time_dxxfjr": 0,
            //             "d7_apply_setup_time_other": 0,
            //             "m1_apply_setup_time_other": 0,
            //             "m3_apply_setup_time_dxxfjr": 0,
            //             "d7_apply_setup_time_ybxffq": 0,
            //             "m3_apply_setup_time_p2pwd": 0
            //         },
            //         "hit_risk_tagging": [],
            //         "ident_info_dict": {
            //             "time_online": "0",
            //             "network_triple_elements": "",
            //             "identity_two_elements": "02"
            //         },
            //         "personal_loan_h": {
            //             "repay_fail6": "0",
            //             "repay_fail1": "0",
            //             "repay_fail3": "0",
            //             "repay_succ6": "0",
            //             "repay_money1": "0",
            //             "repay_succ1": "0",
            //             "repay_succ3": "0",
            //             "repay_succ12": "0",
            //             "repay_money3": "0",
            //             "repay_succ24": "11",
            //             "repay_fail24": "263",
            //             "repay_money24": "[3000,5000)",
            //             "repay_fail12": "1",
            //             "repay_money6": "0",
            //             "repay_money12": "0"
            //         },
            //         "rent_history": {
            //             "m6_apply_time": 0,
            //             "d7_apply_time": 0,
            //             "m6_night_apply_agency_time": 0,
            //             "m12_apply_agency_time": 0,
            //             "m3_apply_agency_time": 0,
            //             "m1_apply_time": 0,
            //             "ident_last_apply_time": "",
            //             "d7_night_apply_agency_time": 0,
            //             "m3_night_apply_time": 0,
            //             "m12_apply_time": 0,
            //             "m12_night_apply_agency_time": 0,
            //             "d7_night_apply_time": 0,
            //             "m1_apply_agency_time": 0,
            //             "m1_night_apply_agency_time": 0,
            //             "phone_contain_ident_ct": 0,
            //             "ident_contain_phone_ct": 0,
            //             "m6_night_apply_time": 0,
            //             "m1_night_apply_time": 0,
            //             "m12_night_apply_time": 0,
            //             "m3_apply_time": 0,
            //             "m6_apply_agency_time": 0,
            //             "d7_apply_agency_time": 0,
            //             "m3_night_apply_agency_time": 0
            //         },
            //         "risk_list_check": {
            //             "credit_rent": 0,
            //             "idcard_hit_fztj": 0,
            //             "result_xd": 0,
            //             "census_register_high_risk_area": 0,
            //             "phone_hit_txxh": 0,
            //             "idcard_hit_clzlwy": 0,
            //             "phone_hit_xjhm": 0,
            //             "idcard_hit_gywzcc": 0
            //         },
            //         "personal_loan_f": {
            //             "lenders": "4",
            //             "lend_time": "2023-08",
            //             "lend_money12": "0",
            //             "lend_money24": "0",
            //             "nllenders": "0",
            //             "lend_number1": "0",
            //             "lend_money3": "0",
            //             "cflenders": "4",
            //             "lend_number3": "0",
            //             "lend_money1": "0",
            //             "lend_money6": "0",
            //             "lend_number6": "0",
            //             "lend_time_distance": "(240, 300)",
            //             "lend_number12": "1",
            //             "lend_number24": "11"
            //         }
            //     }
            // }

            // this.creditInfo.resp_data.hit_risk_tagging=["命中风险关注名单", "涉及法院风险信息记录"]

            // this.creditInfo={
            //     "resp_code": "SW0000",
            //     "resp_data": {
            //         "base_info": {
            //             "age": 31,
            //             "id_card_city": "揭阳市",
            //             "ident_number": "4452************32",
            //             "mobile_address_city": "揭阳市",
            //             "name": "***",
            //             "phone": "137******68"
            //         },
            //         "court_risk_info_list": [
            //             {
            //                 "compatibility": 0.99,
            //                 "data_type": "执行公告",
            //                 "sort_time_string": "2019年03月26日",
            //                 "summary": "茅xx"
            //             },
            //             {
            //                 "compatibility": 0.4,
            //                 "data_type": "裁判文书",
            //                 "sort_time_string": "2018年08月15日",
            //                 "summary": "周xx与茅xx民间借贷纠纷执行裁定书"
            //             },
            //         ],
            //         "hit_risk_tagging": [
            //             "命中风险关注名单",
            //             "涉及法院风险信息记录"
            //         ],
            //         "multiple_score": 52,
            //         "personal_loan_demand": {
            //             "wx_datad7": {
            //                 "common_amortize_platform": 0,
            //                 "credit_card": 0,
            //                 "large_finance_platform": 0,
            //                 "other_platform": 0,
            //                 "p2p_platform": 0,
            //                 "petty_loan_platform": 0,
            //                 "platform_total": 0
            //             },
            //             "wx_datam1": {
            //                 "common_amortize_platform": 0,
            //                 "credit_card": 1,
            //                 "large_finance_platform": 0,
            //                 "other_platform": 2,
            //                 "p2p_platform": 0,
            //                 "petty_loan_platform": 0,
            //                 "platform_total": 3
            //             },
            //             "wx_datam3": {
            //                 "common_amortize_platform": 1,
            //                 "credit_card": 1,
            //                 "large_finance_platform": 1,
            //                 "other_platform": 5,
            //                 "p2p_platform": 2,
            //                 "petty_loan_platform": 1,
            //                 "platform_total": 11
            //             }
            //         },
            //         "personal_loan_f": {
            //             "cflenders": "1",
            //             "distance_now_days": 229,
            //             "lend_number1": "1",
            //             "lend_number3": "2",
            //             "lend_number6": "3",
            //             "lend_time": "2018-10-06",
            //             "lenders": "6",
            //             "loanday": "228",
            //             "nllenders": "0",
            //             "repay_fail1": "0",
            //             "repay_fail12": "2",
            //             "repay_succ1": "0",
            //             "repay_succ12": "3",
            //             "type": "Y"
            //         },
            //         "personal_loan_s": {
            //             "apply_mechanism_number": "12",
            //             "apply_time1": "1",
            //             "apply_time3": "3",
            //             "apply_time6": "6",
            //             "consumer_apply_mechanism_number": "无记录",
            //             "distance_now_days": "无记录",
            //             "last_apply_time": "无记录",
            //             "network_loan_apply_mechanis_mnumber": "无记录",
            //             "type": "N"
            //         },
            //         "personal_overdue_history": {
            //             "data_list": [
            //                 {
            //                     "overdue_day": "M0",
            //                     "overdue_money": "1-1000",
            //                     "overdue_time": "2019-04",
            //                     "settlement": "N"
            //                 },
            //                 {
            //                     "overdue_day": "M0",
            //                     "overdue_money": "1-1000",
            //                     "overdue_time": "2019-03",
            //                     "settlement": "N"
            //                 },
            //             ],
            //             "overdue_mechanism_number": "2",
            //             "overdue_total_counts": "5",
            //             "overdue_total_money": "3600",
            //             "type": "Y",
            //             "uncleared_counts": 5,
            //             "yuqi_boolean": true
            //         },
            //         "relevance_risk_check": {
            //             "ident_to_phone_counts": "2",
            //             "phone_to_ident_counts": 0
            //         },
            //         "risk_list_check": {
            //             "car_rental_break_contract_list": "未命中",
            //             "census_register_hign_risk_area": "未命中",
            //             "court_break_faith_list": "未命中",
            //             "court_case_list": "未命中",
            //             "court_execute_list": "未命中",
            //             "credit_overdue_list": "未命中",
            //             "crime_manhunt_list": "未命中",
            //             "hign_risk_focus_list": "未命中",
            //             "owing_taxes_legal_person_list": "未命中",
            //             "owing_taxes_list": "未命中",
            //             "personal_fraud_blacklist": "未命中",
            //             "riding_break_contract_list": "未命中",
            //             "small_number_base": "未命中",
            //             "student_loan_arrearage_list": "未命中",
            //             "virtual_number_base": "未命中"
            //         },
            //         "score_norm_explain": "分值在 0~100 之间，分值越高风险越大，（0,30] 建议通过，(30,80) 建议审核，[80,100)建议拒绝。",
            //         "verify_recomment": "建议审核"
            //     },
            //     "resp_msg": "认证成功",
            //     "resp_order": "013011558593836513465910",
            //     "timestamp": "20190523144356"
            // }


            // this.creditInfo={
            //     "resp_code":"SW0000",
            //     "resp_data":{
            //         "base_info":{
            //             "age":20,
            //             "id_card_city":"浙江省杭州市",
            //             "ident_number":"142726199808082711",
            //             "mobile_address_city":"浙江省杭州市",
            //             "name":"王瑞鹏",
            //             "phone":"15988126187"
            //         },
            //         "court_risk_info_list":[
            //
            //         ],
            //         "hit_risk_tagging":[
            //
            //         ],
            //         "multiple_score":76,
            //         "personal_loan_demand":{
            //             "wx_datad7":{
            //                 "common_amortize_platform":1,
            //                 "credit_card":0,
            //                 "large_finance_platform":0,
            //                 "other_platform":1,
            //                 "p2p_platform":0,
            //                 "petty_loan_platform":0,
            //                 "platform_total":2
            //             },
            //             "wx_datam1":{
            //                 "common_amortize_platform":1,
            //                 "credit_card":0,
            //                 "large_finance_platform":0,
            //                 "other_platform":3,
            //                 "p2p_platform":0,
            //                 "petty_loan_platform":0,
            //                 "platform_total":4
            //             },
            //             "wx_datam3":{
            //                 "common_amortize_platform":4,
            //                 "credit_card":0,
            //                 "large_finance_platform":4,
            //                 "other_platform":4,
            //                 "p2p_platform":4,
            //                 "petty_loan_platform":2,
            //                 "platform_total":18
            //             }
            //         },
            //         "personal_loan_f":{
            //             "cflenders":"无记录",
            //             "distance_now_days":"无记录",
            //             "lend_number1":"无记录",
            //             "lend_number3":"无记录",
            //             "lend_number6":"无记录",
            //             "lend_time":"无记录",
            //             "lenders":"无记录",
            //             "loanday":"无记录",
            //             "nllenders":"无记录",
            //             "repay_fail1":"无记录",
            //             "repay_fail12":"无记录",
            //             "repay_succ1":"无记录",
            //             "repay_succ12":"无记录",
            //             "type":"N"
            //         },
            //         "personal_loan_s":{
            //             "apply_mechanism_number":"3",
            //             "apply_time1":"0",
            //             "apply_time12":"4",
            //             "apply_time3":"3",
            //             "apply_time6":"3",
            //             "consumer_apply_mechanism_number":"0",
            //             "distance_now_days":64,
            //             "last_apply_time":"2019-04",
            //             "network_loan_apply_mechanis_mnumber":"1",
            //             "type":"Y"
            //         },
            //         "personal_overdue_history":{
            //             "data_list":[
            //
            //             ],
            //             "overdue_mechanism_number":"无记录",
            //             "overdue_total_counts":"无记录",
            //             "overdue_total_money":"无记录",
            //             "type":"N",
            //             "uncleared_counts":"无记录",
            //             "yuqi_boolean":false
            //         },
            //         "relevance_risk_check":{
            //             "ident_to_phone_counts":"2",
            //             "phone_to_ident_counts":0
            //         },
            //         "risk_list_check":{
            //             "car_rental_break_contract_list":"未命中",
            //             "census_register_hign_risk_area":"未命中",
            //             "court_break_faith_list":"未命中",
            //             "court_case_list":"未命中",
            //             "court_execute_list":"未命中",
            //             "credit_overdue_list":"未命中",
            //             "crime_manhunt_list":"未命中",
            //             "hign_risk_focus_list":"未命中",
            //             "owing_taxes_legal_person_list":"未命中",
            //             "owing_taxes_list":"未命中",
            //             "personal_fraud_blacklist":"未命中",
            //             "riding_break_contract_list":"未命中",
            //             "small_number_base":"未命中",
            //             "student_loan_arrearage_list":"未命中",
            //             "virtual_number_base":"未命中"
            //         },
            //         "score_norm_explain":"分值在 0~100 之间，分值越高风险越大，（0,30] 建议通过，(30,80) 建议审核，[80,100)建议拒绝。",
            //         "verify_recomment":"建议审核"
            //     },
            //     "resp_msg":"认证成功",
            //     "resp_order":"013011624513441374349642",
            //     "timestamp":"20210624134401"
            // }


            if(this.creditInfo.resp_code!=null && this.creditInfo.resp_code!=''){
                if(this.creditInfo.resp_code=="SW0000"){
                    this.show=true
                    this.getMultipleScoreColor()
                    this.setApplyMechanismNumber(1)
                    this.setLendNumber(1)
                    this.setWxDatad(7)
                }else{
                    if(this.creditInfo.resp_msg){
                        this.queryErrorMsg = this.creditInfo.resp_msg
                    }

                }
            }

        },
        beforeDestroy() {
        }

    }
</script>

<style scoped>
    .wrap-header{
         top: 0; left: 0; width: 100%; z-index: 999;
        background: url('./../assets/wrap_header_logo.jpg') no-repeat;
        background-size: 100% 100%;
        margin: 0 auto;
        height: 120px;

    }
    .wrap-main{
        top: 8rem; left: 0; width: 100%; z-index: 999;
        background: #F6F5F7;
        background-size: 100% 100%;
        margin: 0 auto;
        text-align: center;
        box-sizing: border-box;
        height: auto;
        width: auto;

        overflow:scroll

    }
    .table th, .table td {
        padding-top: 0px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right:8px;

    }
    .table th{
        color:gray;
    }
    .table td{
        text-align: right;
        color: black;
        font-size: 14px;
        line-height: 25px;

    }






</style>