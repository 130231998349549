<template>
    <b-container  fluid="true" class="h-100">
        <br>

        <b-row >
            <b-col class="ml-1 text-left" cols="1" >
                <b-icon icon="chevron-left" style="width: 25px; height: 25px;"  @click="onGoback"></b-icon>
            </b-col>
            <b-col class="text-center" cols="10" >
                <h5><b> 联系我们 </b>  </h5>
            </b-col>
        </b-row>
        <br>
        <b-row >
            <b-col class="text-center ml-3" cols="11"  >

                <b-card  class="container bg-light col-11 " >
                    <div class="text-left"  style="margin-left: -10px">
                           <b class="ml-1">扫码关注公众号：</b><br>


                            <img  style="width: 200px;height: 200px" src="~@/assets/gzhao.jpg" />


                    </div>
                </b-card>

            </b-col>
        </b-row>

    </b-container>
</template>
<script>

    export default {
        name: "",
        data() {
            return {


            };
        },
        methods: {
            onGoback() {
                this.$router.go(-1);
            },
        },
        mounted() {



        },

    }
</script>
<style >

</style>