import Vue from 'vue'
import VueRouter from 'vue-router'


import Test from '../views/Test.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import NotFound from '../views/404.vue'
import Home from '../views/Home.vue'
import Terms from '../views/Terms.vue'
import UserInfo from '../views/UserInfo.vue'
import Payment from '../views/Payment.vue'
import Order from '../views/Order.vue'
import OrdDetail from '../views/OrderDetail.vue'
import Query from '../views/Query.vue'
import CreditDetail from '../views/CreditDetail.vue'
import TermsForWx from '../views/TermsForWx.vue'
import Contact from '../views/Contact.vue'


Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        component: Query,
        name: 'Query',
    },
    {
        path: '/query',
        component: Query,
        name: 'Query',
    },
    {
        path: '/creditDetail',
        component: CreditDetail,
        name: 'CreditDetail',
    },



    // {
    //     path: '/test',
    //     component: Test,
    //     name: 'Test',
    // },
    {
        path: '/login',
        component: Login,
        name: 'Login',
    },
    {
        path: '/logout',
        component: Logout,
        name: 'Logout',
    },
    {
        path: '/home',
        component: Home,
        name: 'Home',
    },
    {
        path: '/userInfo',
        component: UserInfo,
        name: 'UserInfo',
    },
    // {
    //     path: '/payment',
    //     component: Payment,
    //     name: 'Payment',
    // },
    {
        path: '/order',
        component: Order,
        name: 'Order',
    },
    {
        path: '/ordDetail',
        component: OrdDetail,
        name: 'OrdDetail',
    },

    {
        path: '/terms',
        component: Terms,
        name: 'Terms',
        hidden: true
    },
    {
        path: '/contact',
        component: Contact,
        name: 'Contact',
        hidden: true
    },


    {
        path: '/termsforwx',
        component: TermsForWx,
        name: 'TermsForWx',
        hidden: true
    },
    {
        path: '/404',
        component: NotFound,
        name: '',
        hidden: true
    },
    // {
    //     path: '/',
    //     component: Home,
    //     name: 'Logout',
    //     iconCls: 'fa fa-id-card-o',
    //     leaf: true,
    //     children: [
    //         { path: '/Logout', component: Logout, name: 'Logout',meta: { title: 'Logout'}  }
    //     ]
    // },

    {
        path: '*',
        hidden: true,
        redirect: { path: '/404' }
    },


  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
    mode:"history",
    base:'/',
    routes
})

export default router
