<template>
    <div class="container-fluid">

        <b-row class=" bg-white mt-1 " >
                <b-col class="text-left" cols="1"  >
                    <b-icon icon="chevron-left" style="width: 25px; height: 25px;"  @click="onGoback"></b-icon>
                </b-col>
                <b-col class="text-center" cols="10">
                    <h5><b>支付结果</b>  </h5>
                </b-col>
            </b-row>

            <b-row class=" bg-light  mt-5 py-4 " style="border-top:1px solid lightgrey;border-bottom:1px solid lightgrey">
                <b-col  class="text-left  " cols="7"  >
                    <span style="font-size: 12px;display: inline-block;margin-bottom: 10px" > 订单: <b>{{orderNo}}</b></span>
                    <br>
                    <span style="font-size: 12px;display: inline-block;margin-bottom: 10px" > 描述: <b>{{description}}</b></span>


                </b-col >
                <b-col  class="text-left"  cols="5"  >
                    <span style="font-size: 12px;display: inline-block;margin-bottom: 10px" > 金额: <b>{{amount}}￥</b></span>
                    <br>
                    <span style="font-size: 12px;display: inline-block;margin-bottom: 10px" > 状态: <b>{{statusDesc}}</b> </span>
                </b-col>
            </b-row>


            <b-row class=" bg-white mt-3 p-4 ">

                <b-col class="text-center" >
                    <br>
                    <br>
                    <div v-if="confirmFlg==false">
                        <a hidden id="h5payBtn" src="" target="_blank"/>
                        <form ></form>
                        <div @click.stop class="waiting-pay">
                            <div class="watingpic">
                                <img src="~@/assets/loading.gif" />
                            </div>
                            <p>支付等待</p>

                        </div>
                        <div v-if="tradeType=='NOTIFY'">
                            <img   :src="qrCode"  >
                            <b-card-text>请使用微信扫描二维码完成支付</b-card-text>
                        </div>
                        <br>
                        <br>
                        <div>
                            <b-button  size="sm" variant="outline-primary" @click="onConfirm"  style="margin-right: 10px">支付完成</b-button>
                            <b-button  size="sm"  variant="outline-danger" @click="onGoback">取消支付</b-button>
                        </div>

                        <br>
                        <br>
                    </div>
                    <div v-else>
                        <!--支付失败-->

                        <div v-if="status==0">
                            <img src="~@/assets/pay-default.png" />
                            <h1>未完成支付</h1>
                        </div>
                        <div v-if="status==1">
                            <img src="~@/assets/pay-success.png" />
                            <h1>支付成功</h1>
                        </div>
                        <div v-if="status==2">
                            <img src="~@/assets/pay-default.png" />
                            <h1>支付失败</h1>
                        </div>
                        <div v-if="status==3">
                            <img src="~@/assets/pay-success.png" />
                            <h1>交易成功</h1>
                        </div>
                        <div v-if="status==4">
                            <img src="~@/assets/pay-success.png" />
                            <h1>退款成功</h1>
                        </div>
                        <div v-if="status==5">
                            <img src="~@/assets/pay-default.png" />
                            <h1>支付失败,订单已经关闭</h1>
                        </div>
                        <br>
                        <br>
                        <b-button   variant="success" @click="onGoback">返回</b-button>
                     </div>



                    <br>
                    <br>
                    <br>
                    <br>


                </b-col>
            </b-row>

    </div>
</template>

<script>
    import { requestCreditQuery,requestQueryOrder,requestCloseOrder,requestGetUserInfo,requestGetUserSpaceTime,requestTransactionByCord,requestTransactionByTime } from '../api/api';
    import {Message} from 'element-ui'

    export default {
        name: "",
        data() {
            return {
                show: true,
                tipShow: true,
                loading: false,
                orderNo:null,
                qrCode:null,
                description:"个人报告",
                amount:0,
                status:null,
                statusDesc:"支付等待",
                confirmFlg:false,
                queryInfo: {
                    name: '',
                    identNumber: '',
                    phone: '',
                },
                tradeType:"",
                ordDetailIntervalId:null,
                queryCount:0,
            };
        },
        methods: {
            onGoback() {
                // this.$router.go(-1);
                this.$router.push('/');
            },
            onConfirm() {
                // this.$router.go(-1);
                this.confirmFlg=true;
                this.doQueryOrder()

            },

            doQueryOrder() {


                this.loading = true;
                var params = {orderNo :this.orderNo};
                requestQueryOrder(params).then(data => {
                    this.loading = false;
                    // console.log("data:.................")
                    // console.log(JSON.stringify(data.msg))
                    if (data.code !== "1001") {
                        //pass
                        this.statusDesc=data.msg
                        Message.error({message:data.msg});
                        this.confirmFlg=true
                    } else {
                        // console.log(JSON.stringify(data.data ))
                        let orderJson = data.data;
                        this.initOrderInfo(orderJson)

                    }
                });
            },

            doCreditQuery() {
                this.loading = true;
                //获取session里保存的相关数据
                var params = {orderNo :this.orderNo,name:this.queryInfo.name,phone:this.queryInfo.phone,identNumber:this.queryInfo.identNumber};
                requestCreditQuery(params).then(data => {
                    this.loading = false;
                    // console.log("data:.................")
                    console.log(JSON.stringify(data.msg))
                    if (data.code !== "1001") {
                        //pass
                        //这里调起征信查询
                        //this.$router.push({name:"CreditDetail",query:{}});
                        this.clearOrdDetailIntervalId()
                        this.statusDesc=data.msg
                        Message.error({message:data.msg});

                    } else {
                        console.log(JSON.stringify(data.data ))
                        let creditJson = data.data;

                        localStorage.setItem('creditJson',JSON.stringify(creditJson));

                        //这里调起征信查询
                        // this.$router.push({name:"CreditDetail",query:{}});
                        this.$router.push('/creditDetail');

                    }
                });

            },

            initOrderInfo(order){
                if(order!=null){
                    console.log("orderStr:"+JSON.stringify(order))
                    this.orderNo = order.orderNo
                    this.amount = order.amount
                    this.description = order.description
                    this.status = order.status
                    if(this.status==0){
                        this.statusDesc="付款等待"
                        if(this.queryCount==0){
                            this.statusDesc="未完成付款"
                            this.confirmFlg=true
                        }
                    }else{
                        this.clearOrdDetailIntervalId()
                        if(this.status==1){
                            this.confirmFlg=true
                            this.statusDesc="付款成功"
                        }else if(this.status==2){
                            this.confirmFlg=true
                            this.statusDesc="付款失败"
                        }else if(this.status==3){
                            Message.success({message:"付款成功"});
                            this.confirmFlg=true
                            this.statusDesc="付款成功"

                            //这里调起征信查询
                            this.doCreditQuery()

                        }else if(this.status==4){
                            this.confirmFlg=true
                            this.statusDesc="已退款"
                        }else if(this.status==5){
                            this.confirmFlg=true
                            this.statusDesc="订单关闭"
                        }
                    }


                }
            },
            clearOrdDetailIntervalId(){
                if(this.ordDetailIntervalId!=null){
                    clearInterval(this.ordDetailIntervalId)
                }
            },
            doQueryCountdown(count){
                // 倒计时
                this.queryCount = count;
                this.ordDetailIntervalId = setInterval(() => {
                    if(this.queryCount > 1) {
                        if(this.queryCount%10 == 0){
                            this.doQueryOrder()
                        }
                        this.queryCount--;
                    } else {
                        //先关闭订单
                        this.queryCount=0
                        this.doQueryOrder()
                        this.clearOrdDetailIntervalId()
                    }
                }, 1000);
            },

        },
        mounted() {

            localStorage.removeItem('creditJson');

            //获取参数
            let queryInfoStr= localStorage.getItem('queryInfo');
            console.log("queryInfoStr................."+queryInfoStr)
            this.queryInfo = JSON.parse(queryInfoStr);

            let orderNo  = this.$route.query.out_trade_no;
            let order = null;
            let orderDetailStr= localStorage.getItem('orderDetail');
            if(orderDetailStr!=null && orderDetailStr!='' && orderDetailStr!=undefined){
                order = JSON.parse(orderDetailStr);
                this.orderNo = order.orderNo
            }
            if(orderNo!=null && orderNo!='' && orderNo!=undefined ){
                this.orderNo = orderNo
                console.log("orderNo................."+orderNo)
            }

            if(order!=null && order.status>1){
                this.initOrderInfo(order)
            }else{
                this.doQueryCountdown(30)
            }






            // //这里我们延迟2秒进行查询
            // setTimeout(()=>{
            //     this.doWxQueryOrder()
            // },2000*1);


        },
        beforeDestroy() {
            this.clearOrdDetailIntervalId()
        }

    }
</script>

<style scoped>
    .waiting-pay
    {
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;
    }
    .waiting-pay .watingpic {
        width: 5rem;
        margin: 0.1rem auto 1.18rem;
    }

    .waiting-pay .watingpic img {
        width: 5rem;
        height: 5rem;
    }

    .waiting-pay p {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        color: #b1b1b1;
        font-size: 1rem;
    }
</style>