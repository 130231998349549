<template>
    <div>

    </div>
</template>

<script>
    import { requestLogout } from '../api/api';
    export default {
        name: "",
        methods: {
            //退出登录
            logout: function () {
                var _this = this;
                this.$confirm('确认退出吗?', '提示', {
                    //type: 'warning'
                }).then(() => {
                    requestLogout({}).then((res) => {
                        sessionStorage.removeItem('token');
                        _this.$router.push('/login');
                    });


                }).catch(() => {

                });


            },
        },
        mounted() {
            this.logout();
        },

    }
</script>

<style scoped>

</style>