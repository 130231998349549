import Vue from 'vue'
import axios from 'axios';
import qs from 'qs'


import {Message} from 'element-ui'


export let base =  'https://www.credit-report.cn';
// export let base =  'https://www.credit-report.ltd';
// export let base =  'http://127.0.0.1:8082';

// export let  tokenExpiresTime   = 7 * 24* 60 * 60*1000;
// export let  tokenExpiresTime   = 1 * 60 * 60*1000;
export let  tokenExpiresTime   = 10 * 60*1000;
export let  tokenUpdateTime   = 5 * 60*1000;

export const axiosProxy = params => {
    var axiosInstance = axios.create( {headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'token': getToken()
        }});

    axiosInstance.interceptors.response.use(data => {
            return data;
    },error => {
        if (error.response.status == 401) {
            Message.error({message: 'token 超时!'});
        }
        else if (error.response.status == 504||error.response.status == 404) {
            Message.error({message: '服务器出差了！'});
        } else if (error.response.status == 403) {
            Message.error({message: '权限不足,请联系管理员!'});
        }else {
            Message.error({message: '未知错误!'});
        }
        return Promise.resolve(error);
    });

    return axiosInstance;
}


export const axiosProxyNoToken = params => {
    var axiosInstance = axios.create({headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }});

    axiosInstance.interceptors.response.use(data => {
        return data;
    },error => {
        if (error.response.status == 401) {
            Message.error({message: 'token 超时!'});
        }
        else if (error.response.status == 504||error.response.status == 404) {
            Message.error({message: '服务器出差了！'});
        } else if (error.response.status == 403) {
            Message.error({message: '权限不足,请联系管理员!'});
        }else {
            Message.error({message: '未知错误!'});
        }
        return Promise.resolve(error);
    });

    return axiosInstance;
}


export const setToken = (token)=> {
    console.log("setToken.....................")
    var exdate = new Date();
    exdate.setTime(exdate.getTime() + tokenExpiresTime);
    let tokenJson ={"token":token,"expires":exdate.getTime()}
    localStorage.setItem('token',JSON.stringify(tokenJson));
    console.log("setToken ok.....................")
}

//获取cookie
export const getToken= () =>{
    console.log("getToken.....................")
    var tokenJsonStr = localStorage.getItem('token')
    if(tokenJsonStr!=null){
        var tokenJson = JSON.parse(tokenJsonStr)
        console.log(JSON.stringify(tokenJson))
        var exdate = new Date();
        //提前一个小时个更新token
        var expiresTime = tokenJson.expires
        var token = tokenJson.token
        var updateTime = expiresTime - tokenUpdateTime;

        var d1 = new Date();
        var d2 = new Date();
        d1.setTime(expiresTime)
        d2.setTime(updateTime)
        console.log(expiresTime+"---"+updateTime+"---"+exdate.getTime()+"---"+token)
        console.log(d1+"---"+d2+"---"+exdate+"---"+token)
        return token;
        // if (expiresTime!=null && token!=null && exdate.getTime() <expiresTime){
        //     console.log("getToken ok....................."+token)
        //     return token;
        // }else{
        //     // delToken()
        //     return null
        // }
    }
    return null
}

//删除cookie
export const  delToken=()=> {
    console.log("delToken.....................")
    let tokenJsonStr = localStorage.getItem('token')
    if(tokenJsonStr!=null){
        console.log("delToken ok.....................")
        localStorage.removeItem("token")
    }
}


function updateToken(tokenStr) {
    console.log("updateToken.....................")
    var params = {token: tokenStr};
    console.log(JSON.stringify(params))
    requestUpdateToken(params).then(data => {
        console.log(JSON.stringify(data.msg))
        if (data.code !== "1001") {
            //delToken()
        } else {
            setToken(data.data.newToken);
            console.log("updateToken ok.....................")
        }
        return Promise.resolve(data.data)
    });
}

//更新token
export const requestUpdateToken = params => { return  axiosProxyNoToken().post(`${base}/spacetime/user/updateToken`, qs.stringify(params)).then(res => res.data); };

export const requestLogin = params => { return axiosProxy().post(`${base}/spacetime/user/login`, qs.stringify(params)).then(res => res.data); };

export const requestGetTerms = params => { return axiosProxy().post(`${base}/spacetime/feedback/findFeedbackList`, qs.stringify(params)).then(res => res.data); };

export const requestGetUserInfo = params => { return axiosProxy().post(`${base}/spacetime/user/getUserInfo`, qs.stringify(params)).then(res => res.data); };

export const requestGetUserSpaceTime = params => { return axiosProxy().post(`${base}/spacetime/user/getUserSpaceTime`, qs.stringify(params)).then(res => res.data); };

export const requestRecharge = params => { return axiosProxy().post(`${base}//spacetime/pay/recharge`,qs.stringify(params)).then(res => res.data); };

export const requestQueryOrder = params => { return axiosProxy().post(`${base}//spacetime/pay/queryOrder`,qs.stringify(params)).then(res => res.data); };

export const requestCloseOrder = params => { return axiosProxy().post(`${base}//spacetime/pay/closeOrder`,qs.stringify(params)).then(res => res.data); };


export const requestLogout = params => { return axiosProxy().post(`${base}/spacetime/user/loginOut`, qs.stringify(params)).then(res => res.data); };



export const requestTransactionByCord = params => { return axiosProxy().post(`${base}/spacetime/transaction/findTransactionByCord`, qs.stringify(params)).then(res => res.data); };

export const requestTransactionByTime = params => { return axiosProxy().post(`${base}/spacetime/transaction/findTransactionByTime`, qs.stringify(params)).then(res => res.data); };

export const requestTransactionByTest = params => { return axiosProxy().post(`${base}/spacetime/transaction/findTransactionByTest`, qs.stringify(params)).then(res => res.data); };

export const requestCreditQuery = params => { return axiosProxy().post(`${base}/spacetime/credit/query`, qs.stringify(params)).then(res => res.data); };

export const requesOauth2GetOpenid = params => { return axiosProxy().post(`${base}/spacetime/wxPay/oauth2GetOpenid`, qs.stringify(params)).then(res => res.data); };

export const requesGetWxJsAPiParam = params => { return axiosProxy().post(`${base}/spacetime/wx/getWxJsAPiParam`, qs.stringify(params)).then(res => res.data); };


