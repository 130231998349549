<template>
    <div class="test">
        <span>{{ $t('test.test') }}</span>

        <h1>Bootstrap标题一</h1>
        <h2>Bootstrap标题二</h2>
        <h3>Bootstrap标题三</h3>
        <h4>Bootstrap标题四</h4>
        <h5>Bootstrap标题五</h5>
        <h6>Bootstrap标题六</h6>

        <!--Bootstrap中让非标题元素和标题使用相同的样式-->
        <div class="h1">Bootstrap标题一</div>
        <div class="h2">Bootstrap标题二</div>
        <div class="h3">Bootstrap标题三</div>
        <div class="h4">Bootstrap标题四</div>
        <div class="h5">Bootstrap标题五</div>
        <div class="h6">Bootstrap标题六</div>

        <p class="lead">我是特意要突出的文本。</p>
        <p>我是普通段落文本。</p>
        <strong>我是加粗的文本</strong>
        <p>我在慕课网上跟<em>大漠</em>一起学习<i>Bootstrap</i>的使用。我一定要学会<i>Bootstrap</i>。</p>

        <div class="text-muted">.text-muted 效果</div>
        <div class="text-primary">.text-primary效果</div>
        <div class="text-success">.text-success效果</div>
        <div class="text-info">.text-info效果</div>
        <div class="text-warning">.text-warning效果</div>
        <div class="text-danger">.text-danger效果</div>


        <p class="text-left">我居左</p>
        <p class="text-center">我居中</p>
        <p class="text-right">我居右</p>
        <p class="text-justify">我是左右对齐</p>

        <dl>
            <dt>定义列表标题</dt>
            <dd>定义列表信息1</dd>
            <dd>定义列表信息2</dd>
        </dl>

        <h5>普通列表</h5>
        <ul>
            <li>列表项目</li>
            <li>列表项目</li>
            <li>列表项目</li>
            <li>列表项目</li>
            <li>列表项目</li>
        </ul>
        <h5>有序列表</h5>
        <ol>
            <li>项目列表一</li>
            <li>项目列表二</li>
            <li>项目列表三</li>
        </ol>

        <ol>
            <li>有序项目列表一</li>
            <li>有序项目列表二</li>
            <ol class="list-unstyled">
                <li>有序二级列表项目列表一</li>
                <li>有序二级列表项目列表二</li>
                <ul>
                    <li>无序三级项目列表一</li>
                    <li>无序三级列表项目二</li>
                    <ul class="list-unstyled">
                        <li>无序四级项目列表一</li>
                        <li>无序四级列表项目二</li>
                    </ul>
                    <li>无序三级项目列表三</li>
                </ul>
                <li>有序二级项目列表三</li>
            </ol>
            <li>有序项目列表三</li>
        </ol>


        <ul class="list-inline">
            <li>W3cplus</li>
            <li>Blog</li>
            <li>CSS3</li>
            <li>jQuery</li>
            <li>PHP</li>
        </ul>
        <ol class="list-inline">
            <li>项目列表一</li>
            <li>项目列表二</li>
            <li>项目列表三</li>
        </ol>
        <dl class="dl-horizontal">
            <dt>W3cplus</dt>
            <dd>一个致力于推广国内前端行业的技术博客。它以探索为己任，不断活跃在行业技术最前沿，努力提供高质量前端技术博文</dd>
        </dl>

        <div>
            <code>&lt;code&gt;</code>
        </div>
        <div>
    <pre>
          &lt;ul&gt;
          &lt;li&gt;...&lt;/li&gt;
          &lt;li&gt;...&lt;/li&gt;
          &lt;li&gt;...&lt;/li&gt;
          &lt;/ul&gt;
    </pre>
        </div>
        <div>
            请输入<kbd>ctrl+c</kbd>来复制代码，然后使用<kbd>ctrl+v</kbd>来粘贴代码
        </div>


        <table class="table table-bordered">
            <thead>
            <tr>
                <th>类名</th>
                <th>描述</th>
            </tr>
            </thead>
            <tbody>
            <tr class="active">
                <td>.active</td>
                <td>表示当前活动的信息</td>
            </tr>
            <tr class="success">
                <td>.success</td>
                <td>表示成功或者正确的行为</td>
            </tr>
            <tr class="info">
                <td>.info</td>
                <td>表示中立的信息或行为</td>
            </tr>
            <tr class="warning">
                <td>.warning</td>
                <td>表示警告，需要特别注意</td>
            </tr>
            <tr class="danger">
                <td>.danger</td>
                <td>表示危险或者可能是错误的行为</td>
            </tr>
            </tbody>
        </table>

        <table class="table">
            <thead>
            <tr>
                <th>表格标题</th>
                <th>表格标题</th>
                <th>表格标题</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>表格单元格</td>
                <td>表格单元格</td>
                <td>表格单元格</td>
            </tr>
            </tbody>
        </table>

        <table class="table table-striped">
            <thead>
            <tr>
                <th>表格标题</th>
                <th>表格标题</th>
                <th>表格标题</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>表格单元格</td>
                <td>表格单元格</td>
                <td>表格单元格</td>
            </tr>
            <tr>
                <td>表格单元格</td>
                <td>表格单元格</td>
                <td>表格单元格</td>
            </tr>
            </tbody>
        </table>

        <table class="table table-striped table-bordered table-hover">
            <thead>
            <tr>
                <th>表格标题</th>
                <th>表格标题</th>
                <th>表格标题</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>表格单元格</td>
                <td>表格单元格</td>
                <td>表格单元格</td>
            </tr>
            <tr>
                <td>表格单元格</td>
                <td>表格单元格</td>
                <td>表格单元格</td>
            </tr>
            </tbody>
        </table>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>表格标题</th>
                        <th>表格标题</th>
                        <th>表格标题</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>表格单元格</td>
                        <td>表格单元格</td>
                        <td>表格单元格</td>
                    </tr>
                    <tr>
                        <td>表格单元格</td>
                        <td>表格单元格</td>
                        <td>表格单元格</td>
                    </tr>
                    </tbody>
                </table>
             </div>

        <form role="form">
            <div class="form-group">
                <label for="exampleInputEmail1">邮箱</label>
                <input type="email" class="form-control" id="exampleInputEmail" placeholder="请输入您的邮箱地址">
            </div>
            <div class="form-group">
                <label for="exampleInputPassword1">密码</label>
                <input type="password" class="form-control" id="exampleInputPassword1" placeholder="请输入您的邮箱密码">
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" >记住密码
                </label>
            </div>
            <button type="submit" class="btn btn-default">进入邮箱</button>
        </form>

        <form class="form-horizontal" role="form">
            <div class="form-group">
                <label for="inputEmail3" class="col-sm-2 control-label">邮箱</label>
                <div class="col-sm-10">
                    <input type="email" class="form-control" id="inputEmail3" placeholder="请输入您的邮箱地址">
                </div>
            </div>
            <div class="form-group">
                <label for="inputPassword3" class="col-sm-2 control-label">密码</label>
                <div class="col-sm-10">
                    <input type="password" class="form-control" id="inputPassword3" placeholder="请输入您的邮箱密码">
                </div>
            </div>
            <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox">记住密码
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-sm-offset-2 col-sm-10">
                    <button type="submit" class="btn btn-default">进入邮箱</button>
                </div>
            </div>
        </form>


        <p>内联表单</p>
        <form class="form-inline" role="form">
            <div class="form-group">
                <label class="sr-only" for="exampleInputEmail2">邮箱</label>
                <input type="email" class="form-control" id="exampleInputEmail2" placeholder="请输入你的邮箱地址">
            </div>
            <div class="form-group" >
                <label class="sr-only" for="exampleInputPassword2">密码</label>
                <input type="password" class="form-control" id="exampleInputPassword2" placeholder="请输入你的邮箱密码">
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox">记住密码
                </label>
            </div>
            <button type="submit" class="btn btn-default">进入邮箱</button>
        </form>

        <div class="container">
            <div class="row">
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
            </div>
            <div class="row">
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
            </div>
            <div class="row">
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
                <div class="col-md-1">.col-md-1</div>
            </div>
        </div>


        <div class="container">
            <div class="row">
                <div class="col-md-4">.col-md-4</div>
                <div class="col-md-8">.col-md-8</div>
            </div>
            <div class="row">
                <div class="col-md-4">.col-md-4</div>
                <div class="col-md-5">.col-md-5</div>
                <div class="col-md-3">.col-md-3</div>
            </div>
            <div class="row">
                <div class="col-md-5">.col-md-5</div>
                <div class="col-md-5">.col-md-5</div>
                <div class="col-md-2">.col-md-2</div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-md-4 c4">col-md-4</div>
                <div class="col-md-4 c4">col-md-4</div>
                <div class="col-md-4 c4">col-md-4</div>
                <!-- 说明：每row行共12列，分个3div，每个div平占4列，即3个*4列=12列 -->
            </div>
            <div class="row">
                <div class="col-md-4 c4">col-md-4</div>
                <div class="col-md-8 c8">col-md-8</div>
                <!-- 说明：每row行共12列，分个2div，第1个div占4列，第2个div则占8列，即4列+8列=12列 -->
            </div>
            <div class="row">
                <div class="col-md-3 c3">col-md-3</div>
                <div class="col-md-6 c6">col-md-6</div>
                <div class="col-md-3 c3">col-md-3</div>
                <!-- 说明：每row行共12列，分个3div，每1,3个div占3列，第2个div则占6列，即3列+6列+3列=12列 -->
            </div>

            <div class="col-xs-12 col-sm-9 col-md-6 col-lg-3" style="background: #1e7e34">
                col-xs-12 col-sm-9 col-md-6 col-lg-3
            </div>

            <a>ddddddddd</a>
        </div>

        <!--<HelloWorld/>-->
        <!--<Navbar />-->
        <!--<AlertTest/>-->
        <!--<BadgeTest/>-->
        <!--<BreadcrumbTest/>-->
        <!--<ButtonTest/>-->
        <!--<ButtonGroupTest/>-->
        <!--<ButtonToolbarTest/>-->
        <!--<CalendarTest/>-->
        <!--<CardTest/>-->
        <!--<CarouselTest/>-->
        <!--<CollapseTest/>-->
        <!--<DropdownTest/>-->
        <!--<EmbedTest/>-->
        <!--<FormTest/>-->
        <!--<CheckboxTest/>-->
        <!--<DatepickerTest/>-->
        <!--<FileTest/>-->
        <!--<FormGroupTest/>-->
        <!--<InputsTest/>-->
        <!--<RadiosTest/>-->
        <!--<TimepickerTest/>-->
        <!--<ImagesTest/>-->
        <!--<FlexboxTest/>-->
        <!--<TooltipTest/>-->
        <!--<ToastTest/>-->
        <!--<TimeTest/>-->
        <!--<TabsTest/>-->
        <!--<TablesTest2/>-->
        <!--<SpinnersTest/>-->
        <!--<ProgressTest/>-->
        <!--<PopoverTest/>-->
    </div>
</template>

<script>
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'
    // import Navbar from '@/components/Navbar.vue';
    // import AlertTest from '@/components/AlertTest.vue';
    // import BadgeTest from '@/components/BadgeTest.vue';
    // import BreadcrumbTest from '@/components/BreadcrumbTest.vue';
    // import ButtonTest from '@/components/ButtonTest.vue';
    // import ButtonGroupTest from '@/components/ButtonGroupTest.vue';
    // import ButtonToolbarTest from '@/components/ButtonToolbarTest.vue';
    // import CalendarTest from '@/components/CalendarTest.vue';
    // import CardTest from '@/components/CardTest.vue';
    // import CarouselTest from '@/components/CarouselTest.vue';
    // import CollapseTest from '@/components/CollapseTest.vue';
    // import DropdownTest from '@/components/DropdownTest.vue';
    // import EmbedTest from '@/components/EmbedTest.vue';
    // import FormTest from '@/components/FormTest.vue';
    // import CheckboxTest from '@/components/CheckboxTest.vue';
    // import DatepickerTest from '@/components/DatepickerTest.vue';
    // import FileTest from '@/components/FileTest.vue';
    // import FormGroupTest from '@/components/FormGroupTest.vue';
    // import InputsTest from '@/components/InputsTest.vue';
    // import RadiosTest from '@/components/RadiosTest.vue';
    // import TimepickerTest from '@/components/TimepickerTest.vue';
    // import ImagesTest from '@/components/ImagesTest.vue';
    // import FlexboxTest from '@/components/FlexboxTest.vue';
    // import TooltipTest from '@/components/TooltipTest.vue';
    // import ToastTest from '@/components/ToastTest.vue';
    // import TimeTest from '@/components/TimeTest.vue';
    // import TabsTest from '@/components/TabsTest.vue';
    // import TablesTest2 from '@/components/TablesTest2.vue';
    // import SpinnersTest from '@/components/SpinnersTest.vue';
    // import ProgressTest from '@/components/ProgressTest.vue';
    // import PopoverTest from '@/components/PopoverTest.vue';







    export default {
        name: 'Home',
        components: {
            // Navbar,
            // HelloWorld,
            // AlertTest
            // BadgeTest
            // BreadcrumbTest,
            // ButtonTest
            // ButtonGroupTest
            // ButtonToolbarTest
            // CalendarTest,
            // CardTest,
            // CarouselTest
            // CollapseTest
            // DropdownTest
            // EmbedTest
            // FormTest
            // CheckboxTest
            // DatepickerTest
            // FileTest
            // FormGroupTest
            // InputsTest
            // RadiosTest
            // TimepickerTest
            // ImagesTest
            // FlexboxTest
            // TooltipTest
            // ToastTest
            // TimeTest
            // TabsTest
            // TablesTest2
            // SpinnersTest
            // ProgressTest
            // PopoverTest
        }
    }
</script>
<style>
    /*<!--*/
    /*1、col是column简写：列；*/
    /*2、xs是maxsmall简写：超小， sm是small简写：小，  md是medium简写：中等， lg是large简写：大；*/
    /*3、-* 表示占列数，即占每行row分12列栅格系统比；*/
    /*4、.col-xs-* 超小屏幕如手机 （<768px）时使用；*/
                             /*.col-sm-* 小屏幕如平板 （768px ≤ 宽度 ＜992px）时使用；*/
                             /*.col-md-* 中等屏幕如普通显示器 （992px ≤ 宽度 ＜ 1200px）时使用；*/
                                                                    /*.col-lg-* 大屏幕如大显示器 （≥1200px）时使用。*/
                                                                    /*当屏幕尺寸*/
                                                                    /*小于 768px 的时候，用 col-xs-12 类对应的样式；*/
                                                                          /*在 768px 到 992px 之间的时候，用 col-sm-9 类对应的样式；*/
                                                                                       /*在 992px 到 1200px 之间的时候，用 col-md-6 类对应的样式；*/
                                                                                                     /*大于 1200px 的时候，用 col-lg-3 类对应的样式；*/

                                                                                                            /*解释*/
    /*1、栅格系统都会自动的把每行row分为12列，*/
     /*col-xs-*、col-sm-* 、col-md-*和.col-lg-* 后面跟的参数表示在当前的屏幕中的占列数。*/
     /*例如 <div class="col-xs-6 col-md-3"></div> 这个div在屏幕中占的位置是：*/
                                         /*.col-xs-6 在超小屏幕中占6列，也就是屏幕的一半（12/6列=2个div），*/
                                                                             /*.col-md-3 在中等屏幕中占3列也就是1/4（12/3列=4个div）。*/

    /*2、反推，*/
    /*如果我们要在移动端并排显示3个div(12/3个=每个占4 列 )，则col-xs-4；*/
    /*在PC端上显示6个div(12/6个=每个占2列 ) ，则 col-md-2。*/
    /*-->*/


    /*[class *= col-]{*/
        /*background-color: #eee;*/
        /*border: 1px solid #ccc;*/
    /*}*/
    /*[class *= col-] [class *= col-] {*/
        /*background-color: #f36;*/
        /*border:1px dashed #fff;*/
        /*color: #fff;*/
    /*}*/

    /*.c3 {*/
        /*background: #c69500;*/
    /*}*/

    /*.c4 {*/
        /*background: #20c997;*/
    /*}*/

    /*.c6 {*/
        /*background: #6f42c1;*/
    /*}*/

    /*.c8 {*/
        /*background: #ed969e;*/
    /*}*/
    /*body {*/
        /*background-color: #fff;*/
        /*@font-family-base:10px;*/
        /*@font-size-base:20px;*/
        /*@line-height-bas:10px;*/
        /*@link-color:#fff;*/
    /*}*/

</style>

