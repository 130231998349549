<template>
    <div class="container-fluid">

            <b-row class=" bg-white mt-1 " >
                <b-col class="text-left" cols="1"  >
                    <b-icon icon="chevron-left" style="width: 25px; height: 25px;"  @click="onGoback"></b-icon>
                </b-col>
                <b-col class="text-center" cols="10">
                    <h5><b>订单支付</b>  </h5>
                </b-col>
            </b-row>

            <span>倒计时{{payCount}}s</span>

            <b-row class=" bg-light  mt-5 py-4 " style="border-top:1px solid lightgrey;border-bottom:1px solid lightgrey;">
                <b-col  class="text-left col-7"  >
                    <span style="font-size: 12px;display: inline-block;margin-bottom: 10px" > 订单: <b>{{orderNo}}</b></span>
                    <br>
                    <span style="font-size: 12px;display: inline-block;margin-bottom: 10px" > 描述: <b>{{description}}</b></span>
                </b-col >
                <b-col  class="text-right"  cols="5"  >
                    <span style="font-size: 12px;display: inline-block;margin-top: 20px" > 订单金额: <b>{{amount}}￥</b></span>
                </b-col>
            </b-row>

            <b-row class=" bg-white mt-1 py-1">
                <b-tooltip show target="wxpay" placement="top" variant="danger" noninteractive>推荐 </b-tooltip>
                <b-col class="text-left" >
                    <div style="display: block;background-color: indianred;position: relative;width: 10px;height: 2px;top:20px;left:60px" id="wxpay"></div>
                    <b-card no-body   >
                        <b-tabs card  >
                            <b-tab :title="tradeTypeDesc" active class="text-center"  >
                                <br>
                                <br>
                                <div v-if="tradeType=='3'">
                                    <img   :src="qrCode"  >
                                    <b-card-text>请用微信扫描二维码完成支付</b-card-text>
                                </div>
                                <div @click.stop class="waiting-pay"   v-else>
                                    <a hidden id="h5payBtn" src="" target="_blank"/>
                                    <div class="watingpic">
                                        <img src="~@/assets/loading.gif" />
                                    </div>
                                    <p>支付等待</p>

                                </div>


                                <br>
                                <br>
                                <div>
                                    <b-button  size="sm" variant="outline-primary" @click="onOrderDetail"  style="margin-right: 10px">支付完成</b-button>
                                    <b-button  size="sm"  variant="outline-danger" @click="onGoback">取消支付</b-button>
                                </div>

                                <br>
                                <br>
                            </b-tab>

                        </b-tabs>
                    </b-card>
                </b-col>
            </b-row>
    </div>
</template>

<script>
    import { requestQueryOrder,requestCloseOrder,requestGetUserInfo,requestGetUserSpaceTime,requestTransactionByCord,requestTransactionByTime } from '../api/api';
    import {Message} from 'element-ui'
    import wx from 'weixin-jsapi'

    export default {
        name: "",
        data() {
            return {
                show: true,
                tipShow: true,
                loading: false,
                orderNo:null,
                qrCode:null,
                description:null,
                amount:0,
                orderIntervalId:null,
                payCount:0,
                isCount:false,
                tradeType:"",
                tradeTypeDesc:"微信支付",
                waitingPay:true,
                payData:null,


            };
        },
        methods: {
            getTradeTypeDesc(){
                return this.tradeTypeDesc
            },
            onGoback() {
                this.$router.go(-1);
            },
            onOrderDetail() {
                this.$router.push({name:"OrdDetail",query:{out_trade_no:this.orderNo}});
            },
            onAliPay(){
                console.log("onAliPay:.................")
                const div = document.createElement('div')
                /* 下面的data.content就是后台返回接收到的数据 */
                div.innerHTML = this.payUrl
                document.body.appendChild(div)
                document.forms[0].submit()

            },
            onH5Pay(){
                console.log("htpay:.................")
                let redirect_url ="https://www.credit-report.cn/#/ordDetail?out_trade_no="+this.orderNo;
                // document.getElementById("h5payBtn").href = this.payUrl+'&redirect_url=' + encodeURIComponent(redirect_url);
                // document.getElementById("h5payBtn").click();

                window.location.href=this.payUrl+'&redirect_url=' + encodeURIComponent(redirect_url);
                // let wxpayUrl =this.payUrl+'&redirect_url=' + encodeURIComponent(redirect_url);
                // var form = document.forms[0];
                // form.method= "post";
                // form.action= wxpayUrl;
                // form.submit();

            },
            doQueryOrder() {
                this.loading = true;
                var params = {orderNo :this.orderNo};
                requestQueryOrder(params).then(data => {
                    this.loading = false;
                    // console.log("data:.................")
                    // console.log(JSON.stringify(data.msg))
                    if (data.code !== "1001") {
                        //pass
                    } else {
                        // console.log(JSON.stringify(data.data ))
                        let orderJson = data.data;
                        localStorage.setItem('orderDetail',JSON.stringify(orderJson));
                        if(orderJson.status>=1){
                            this.clearOrderIntervalId()
                            this.isCount = false;
                            //this.$router.push('/orderDetail');
                            this.$router.push({name:"OrdDetail",query:{out_trade_no:this.orderNo}});

                        }
                    }
                });
            },
            doCloseOrder() {
                this.loading = true;
                var params = {orderNo :this.orderNo};
                requestCloseOrder(params).then(data => {
                    this.loading = false;
                    console.log("data:.................")
                    console.log(JSON.stringify(data.msg))
                    if (data.code !== "1001") {
                        //pass
                    } else {
                        // console.log(JSON.stringify(data.data ))
                        let orderJson = data.data;
                        localStorage.setItem('orderDetail',JSON.stringify(orderJson));
                    }
                    console.log("closeOrder..........." +this.orderNo)
                    this.$router.push({name:"OrdDetail",query:{out_trade_no:this.orderNo}});

                });
            },
            clearOrderIntervalId(){
                if(this.orderIntervalId!=null){
                    clearInterval(this.orderIntervalId)
                }
            },
            // initOrderIntervalId(){
            //     this.clearOrderIntervalId()
            //     this.orderIntervalId=setInterval(()=>{
            //         this.doWxQueryOrder()
            //
            //     },1000*1)
            // },

            doPayCountdown(count){
                this.isCount = true;
                // 倒计时
                this.payCount = count;
                this.orderIntervalId = setInterval(() => {
                    if(this.payCount > 1) {
                        if(this.payCount%10 == 0){
                            this.doQueryOrder()
                        }
                        this.payCount--;
                    } else {
                        //先关闭订单
                        this.payCount=0
                        this.doCloseOrder()
                        this.clearOrderIntervalId()
                        this.isCount = false;
                        //this.$router.push('/orderDetail');
                        this.$router.push({name:"OrdDetail",query:{out_trade_no:this.orderNo,rtnType:this.tradeType}});
                    }
                }, 1000);
            },

            onJSAPIPay(){
                wx.config({
                    debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
                    appId:  this.payData.appId, // 必填，公众号的唯一标识
                    timestamp:  this.payData.timeStamp, // 必填，生成签名的时间戳
                    nonceStr:  this.payData.nonceStr, // 必填，生成签名的随机串
                    signature:  this.payData.paySign, // 必填，签名
                    jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表
                })
                wx.ready(() => {
                    wx.checkJsApi({
                        jsApiList: ['chooseWXPay'],
                        success:function(res){
                            console.log("seccess")
                            //console.log('hskdjskjk', res)
                        },
                        fail:function(res){
                            console.log("fail");
                            //console.log(res)
                        }
                    })
                    wx.chooseWXPay({
                        timestamp: this.payData.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                        nonceStr: this.payData.nonceStr, // 支付签名随机串，不长于 32 位
                        package: this.payData.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                        signType: this.payData.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                        paySign: this.payData.paySign, // 支付签名
                        success: function (res) {  // 支付成功后的回调函数
                            //alert(res.errorMsg)
                        },
                        fail: function (res) {
                            alert("支付失败");
                            //alert(res.errMsg);
                        }
                    })
                })
                wx.error(err => {
                    alert(err)
                })
            }

        },
        mounted() {
            // this.showWxpayTip()
            localStorage.removeItem('orderDetail');

            let orderPayStr = localStorage.getItem("orderPayStr");
            if(orderPayStr!=null){
                let order = JSON.parse(orderPayStr);
                console.log("orderStr:"+orderPayStr)
                this.orderNo = order.orderNo
                this.amount = order.amount
                this.description = order.description
                this.payUrl = order.payUrl
                this.tradeType = order.tradeType
                this.qrCode = order.qrCode
                this.payData = order.payData
                if(this.tradeType=='2' || this.tradeType=='3' || this.tradeType=='4'){
                    this.tradeTypeDesc="微信支付"
                }else{
                    this.tradeTypeDesc="支付宝支付"
                }

                //在交易前先查询订单
                this.doQueryOrder();

                if(this.tradeType=="2") {
                    this.onH5Pay()
                }

                if(this.tradeType=="4") {
                    this.onJSAPIPay()
                }

                if(this.tradeType=="5" || this.tradeType=="6") {
                    this.onAliPay()
                }
                //
                // //开启定时器查询订单状态 默认5分钟 到了后关闭支付页面
                this.doPayCountdown(1*60)



            }



        },
        beforeDestroy() {
            this.clearOrderIntervalId()
        }

    }
</script>

<style scoped>
    .waiting-pay
    {
        width: 100%;
        height: 100%;
        background-color: #FFFFFF;
    }
    .waiting-pay .watingpic {
        width: 5rem;
        margin: 0.1rem auto 1.18rem;
    }

    .waiting-pay .watingpic img {
        width: 5rem;
        height: 5rem;
    }

    .waiting-pay p {
        width: 80%;
        margin: 0 auto;
        text-align: center;
        color: #b1b1b1;
        font-size: 1rem;
    }


</style>