<template>
  <div class="app">
    <b>home</b>
    <router-view  />
  </div>
</template>

<script>


export default {
    name: 'App'
}
</script>

<style>

</style>
