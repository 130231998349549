import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI, {
    size: 'small'
});

import VueI18n from 'vue-i18n'


Vue.use(VueI18n) // ͨ���������ʽ����

let localelang=''
if(localStorage.getItem('locale_lang')){
    localelang=window.localStorage.getItem('locale_lang');
}
if(!localelang){
    localelang='zh-CN'
}


const i18n = new VueI18n({
    locale: localelang,    // ���Ա�ʶ
    //this.$i18n.locale // ͨ���л�locale��ֵ��ʵ�������л�
    messages: {
        'zh-CN': require('./common/js/langs/zh'),   // �������԰�
        'en-US': require('./common/js/langs/en')    // Ӣ�����԰�
    }
})

//'bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

// router.beforeEach((to, from, next) => {
//     let user =sessionStorage.getItem('token');
//     if (!user && to.path != '/login') {
//         next({path: '/login'})
//     } else {
//         next()
//     }
// })


new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
