<template>
    <div class="container-fluid"  id="userInfo">

        <b-row class=" bg-white mt-1 " >
            <b-col class="text-center" cols="10">
                <h5><b>用户信息</b>  </h5>
            </b-col>
            <b-col class="text-right" cols="2"  >
                <span  style="width: 25px; height: 25px;"  @click="onLogout">退出</span>
            </b-col>
        </b-row>

        <b-row class=" bg-light  mt-5 py-4 " style="border-top:1px solid lightgrey;border-bottom:1px solid lightgrey;">
            <b-col  class="text-left col-5"  >
                <span  style="width: 25px; height: 25px;"  @click="onQuery">征信查询</span>
            </b-col >

        </b-row>

        <b-row class=" bg-light  mt-5 py-4 " style="border-top:1px solid lightgrey;border-bottom:1px solid lightgrey;">
            <b-col  class="text-left col-5"  v-if="userInfo">
                <li v-for="(value, key) in userInfo" v-bind:key="key">
                    {{ key }} : {{ value }}
                </li>
            </b-col >

        </b-row>
    </div>
</template>



<script>
    import { requestGetUserInfo } from '../api/api';
    import {Message} from 'element-ui'
    export default {
        name: "",
        data() {
            return {
                show:true,
                loading: false,
                userInfo:null
            };
        },
        methods: {
            onGoback() {
                this.$router.go(-1);
            },
            onLogout(){
                this.$router.push('/logout');
            },
            onQuery(){
                this.$router.push('/query');
            },
            getUserInfo() {
                this.loading = true;
                var params = { };
                requestGetUserInfo(params).then(data => {
                    this.loading = false;
                    console.log("data:.................")
                    console.log(JSON.stringify(data.msg))
                    if (data.code !== "1001") {
                        this.$message({
                            message: data.msg,
                            type: 'error'
                        });
                    } else {
                        this.userInfo = data.data
                        console.log(JSON.stringify(this.userInfo))
                        sessionStorage.setItem('userInfo',data.data);
                    }
                });
            },
        },
        mounted() {
            this.getUserInfo()
        }
    }
</script>

<style scoped>

</style>